import React, {useContext, useEffect, useState} from "react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import ClientList from "./ClientList";
import Fuse from 'fuse.js';
import _ from "lodash";
import LoadingScreenStep1 from "./loadingScreen";
import { ref as firebase_ref, onValue } from "firebase/database";
import {firebaseDB} from "../../../../firebase/config";
import {DocumentBuilderContext} from "../context";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  List,
  ListItem,
  ListItemIcon, ListItemText, InputAdornment
} from "@material-ui/core";
import axios from "axios";
import {CloudFunctionAuthSecret, GCP_PROJECT_ID} from "../../config";
import {Close, Folder, FolderOpen} from "@material-ui/icons";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {grayColor} from "../../../../assets/jss/material-dashboard-pro-react";
import Button from "../../../../components/CustomButtons/Button";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {IntroLauncher} from "../../intro_js";

export function ClientLookUp(){
  const [clients, setClients] = useState([]); // actual client names, e.g. "Abilex"
  const [loading, setLoading] = useState(true);
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);
  const [searchText, setSearchText] = useState(""); // the text by which the use searches for a specific company name
  useEffect(() => {
    loadClients();
  }, [])

  // uses fuse.js to run a fuzzy search on all clients
  // this is necessary, because Google Drive API does not have a fuzzy search available
  const filterClients = () => {
    let results = _.sortBy(clients, [el => el.name.trim()]);
    if (searchText.length > 2){
      const fuseOptions = {
        keys: ['name'],
        threshold: .3
      };
      const fuse = new Fuse(clients, fuseOptions);
      results = fuse.search(searchText).map( el => el.item);
      results.push({id: -1, name: `Neu erstellen: "${searchText}"`});
    }
    return results.sort();
  }

  const loadClients = () => {
    console.log("loading clients...")
    const db = firebaseDB;
    const clientsRef = firebase_ref(db, 'clients/folders');
    onValue(clientsRef, (snapshot) => {
      let data = snapshot.val();
      data = Object.keys(data).map( el => {
        return {id: el, name: data[el]}
      })
      setClients(data);
      setLoading(false);
    })
  }

  const closeClientFolderDialog = () => {
    updateDocument({ ...documentData, clientName: undefined, clientFolderId: undefined, tenderFolder: undefined })
  }

  return <div>
    {loading && <LoadingScreenStep1/>}
    <SearchInput
      id="pageOneStepOneClientSearchInput"
      val={searchText}
      onChange={ val => setSearchText(val)}
    />
    <ClientList clients={filterClients()}/>
    {documentData.clientName !== undefined && documentData.tenderFolder === undefined && <ClientFolderDialog onClose={closeClientFolderDialog}/>}
  </div>
}


function SearchInput({onChange, value, id}){
  return <div>
    <CustomInput
      labelText={<span>
        Firma suchen...
      </span>}
      id={id}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        onChange: e => onChange(e.target.value),
        value: value,
      }}
    />
  </div>
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ClientFolderDialog({onClose}){
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);
  const [loading, setLoading] = useState(false);
  const [folderData, setFolderData] = useState(null);
  const [tenderName, setTenderName] = useState("");
  const [error, setError] = useState("");

  let currentDate = new Date();
  let year = currentDate.getFullYear().toString().substring(2);
  let month = currentDate.getMonth() + 1;
  month = month >= 10 ? month.toString() : "0" + month.toString();
  let tenderName_prefix = `${year}${month} ${documentData.clientName}`.replace("Neu erstellen: ", "").replace(/"/g, '');

  useEffect(()=>{
    if (documentData.clientName && !documentData.clientName.startsWith("Neu erstellen: ")){
      getFolderData()
    }
  }, [])

  const getFolderData = () => {
    setLoading(true);
    let url = `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/updateFirebaseRTDB-noAuth`;
    let params = {
      clientFolderId: documentData.clientFolderId,
      action: "getClientTenderFolderStructure"
    }
    let headers = {
      'Authorization': "Bearer " + CloudFunctionAuthSecret
    }
    axios({
      method: 'GET',
      url,
      headers,
      params
    }).then( r => {
      setFolderData(r.data);
      setLoading(false);
    }).catch( err => {
      setLoading(false);
      console.log(err)
    }, [])
  }

  const createTenderFolder = () => {
    setLoading(true);
    let _tenderName = tenderName_prefix + " " + tenderName
    let url = `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/updateFirebaseRTDB-noAuth`;
    let params = {
      clientFolderId: documentData.clientFolderId,
      tenderName: _tenderName,
      action: "createTenderFolderStructure",
      userIsTenderManager: documentData.isTenderTeamMember,
      clientName: documentData.clientName.replace("Neu erstellen: ", "").replace(/"/g, '')
    }
    let headers = {
      'Authorization': "Bearer " + CloudFunctionAuthSecret
    }
    axios({
      url,
      params,
      headers
    }).then( r => {
      setLoading(false);
      let newData = {...documentData};
      newData.tenderFolder = r.data;
      updateDocument(newData);
    }).catch(err => {
      setLoading(false);
      setError("Hier ist was schief gegangen... bitte lade die Seite neu...")
      console.log(err)
    })
  }

  const handleSelectFolder = (tenderFolder) => {
    let newData = {...documentData};
    newData.tenderFolder = tenderFolder;
    updateDocument(newData);
  }

  return <div>
    {loading && <LoadingScreenStep1 />}
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>true}
      aria-labelledby="clientFolder-title"
      aria-describedby="clientFolder-description"
    >
      <DialogTitle id="clientFolder-title">
        {folderData ? "Tender Ordner wählen oder neu erstellen" : `Firma ${documentData.clientName} neu erstellen`} <Close style={{position: "absolute", top: 14, right: 14, cursor: 'pointer'}} onClick={onClose}/>
      </DialogTitle>
      <DialogContent>
        <div style={{position: "relative"}}>
          <IntroLauncher action="clientFolderDialog"/>
        </div>
        <GridContainer>
          {folderData && <GridItem xs={12} md={7} id="clientYearFolderStructure">
            <List style={{borderColor: grayColor[4]}}>
              {error}
              {error.length > 0 && <p><strong>{error}</strong></p>}
              {folderData && folderData.year_folders && folderData.year_folders.length < 1 && <SnackbarContent
                message={"Es wurden keine Tender gefunden. Bitte erstellen einen neuen."}
                color="info"
              />}

              {folderData && folderData.year_folders && folderData.year_folders.map( yearFolder => <div key={yearFolder.id}>
                <ListItem style={{fontWeight: 500}}>
                  <ListItemIcon>
                    <FolderOpen />
                  </ListItemIcon>
                  <ListItemText primary={yearFolder.name}/>
                </ListItem>
                <List>
                  {yearFolder.children.map( child => <ListItem key={child.id} button style={{paddingLeft: 40}} onClick={ () => handleSelectFolder(child) }>
                    <ListItemIcon>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText primary={child.name} />
                  </ListItem>)}
                </List>
              </div>)}
            </List>
          </GridItem>}
          <GridItem xs={12} md={5} style={{display: "flex", flexDirection: "column", justifyContent: "center"}} id="createNewClientFolderStructure">
            <CustomInput
              labelText="Tender-Name"
              id="tenderNameInput"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: tenderName,
                onChange: e => setTenderName(e.target.value),
                startAdornment: <InputAdornment position="start" style={{opacity: .75}}>{tenderName_prefix}</InputAdornment>
              }}
            />
            <div id="createNewClientFolderStructure_button">
              <Button color="primary" onClick={createTenderFolder}>
                neu erstellen
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  </div>
}

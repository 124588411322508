import React, {useContext} from "react";
import cx from "classnames";
import {Switch, Route, Redirect} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import {AuthContext} from "../firebase/context";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
    const {...rest} = props;
    const {user} = useContext(AuthContext);
    // states and functions
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [miniActive, setMiniActive] = React.useState(true);
    // styles
    const classes = useStyles();
    const mainPanelClasses =
        classes.mainPanel +
        " " +
        cx({
            [classes.mainPanelSidebarMini]: miniActive,
            [classes.mainPanelWithPerfectScrollbar]:
            navigator.platform.indexOf("Win") > -1,
        });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
    React.useEffect(() => {
        const container = document.querySelector("#mainPanelPerfectScrollbar");
        if (false) {
            //if (navigator.platform.indexOf("Win") > -1) {
            console.log("initializing psb...")
            ps = container && new PerfectScrollbar(container, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {
            if (false) {
                //if (navigator.platform.indexOf("Win") > -1) {
                container && ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, []);
    // functions for changeing the states from components
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };
    const getActiveRoute = (routes) => {
        let activeRoute = "SalesPekka - Angebote für die Randstad Gruppe";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    return (
        <div>
            {!user
                ? <Redirect to={{pathname: "/auth/login"}}/>
                : <div className={classes.wrapper}>
                    {/*<Sidebar
            routes={routes}
            logoText={"Proposal Tool"}
            logo={require("assets/img/logo-white.svg").default}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={"blue"}
            bgColor={"white"}
            miniActive={miniActive}
            {...rest}
          />*/}
                    <div className={mainPanelClasses} ref={mainPanel} id="mainPanelPerfectScrollbar">
                        <AdminNavbar
                            sidebarMinimize={sidebarMinimize.bind(this)}
                            miniActive={miniActive}
                            brandText={getActiveRoute(routes)}
                            handleDrawerToggle={handleDrawerToggle}
                            {...rest}
                        />

                        {getRoute()
                            ? (
                                <div className={classes.content}>
                                    <div className={classes.container}>
                                        <Switch>
                                            {getRoutes(routes)}
                                            <Redirect from="/" to="/admin/dashboard"/>
                                        </Switch>
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.map}>
                                    <Switch>
                                        {getRoutes(routes)}
                                        <Redirect from="/" to="/admin/dashboard"/>
                                    </Switch>
                                </div>
                            )}
                        {getRoute() ? <Footer fluid/> : null}
                    </div>
                </div>
            }
        </div>
    );
}

import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import {primaryColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {DocumentBuilderContext} from "../context";
import {ListItemIcon} from "@material-ui/core";
import {Add} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    border: `2px solid ${primaryColor[0]}`,
    borderTop: "none",
    marginTop: -18
  },
}));

function renderRow(props) {
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);
  const item = props.data[props.index];
  const isSelected = item.name === documentData.clientName;
  const isSelectedStyle = isSelected
    ? {
      backgroundColor: primaryColor[0],
      color: "#fff",
    }
    : {}
  const handleChooseClient = () => {
    updateDocument({ ...documentData, clientName: item.name, clientFolderId: item.id, tenderFolder: undefined });
  }

  return (
    <ListItem button style={{...props.style, ...isSelectedStyle}} key={props.index} onClick={ handleChooseClient }>
      <ListItemText primary={item.name}/>
      {item.name.startsWith("Neu erstellen") && <ListItemIcon style={{display: "flex", justifyContent: "flex-end"}}>
        <Add style={{background: primaryColor[0], color: "#fff", borderRadius: "50%"}}/>
      </ListItemIcon>}
    </ListItem>
  );
}

export default function ClientList({clients}) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="pageOneStepOneClientList">
      <FixedSizeList itemData={clients} itemCount={clients.length} height={400} itemSize={48}>
        {renderRow}
      </FixedSizeList>
    </div>
  );
}

import SalesPekka from "views/ProposalBuilder/ProposalBuilder.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LoginPage from "./views/auth/LoginPage";

const dashRoutes = [
  {
    path: "/home",
    name: "SalesPekka - Angebote für die Randstad Gruppe",
    icon: DashboardIcon,
    component: SalesPekka,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "SalesPekka - Angebote für die Randstad Gruppe",
    icon: DashboardIcon,
    component: LoginPage,
    layout: "/auth",
  },
];
export default dashRoutes;

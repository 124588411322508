import {useState} from "react";
import Button from "../../../components/CustomButtons/Button";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {Add} from "@material-ui/icons";
import {firebaseDB} from "../../../firebase/config";
import {set, ref} from "firebase/database";

export default function DeleteTemplates({docType}){
    const [templates, setTemplates] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [deletedTemplates, setDeletedTemplates]  = useState([]);

    const handleAddTemplate = () => {
        let _inputValue = inputValue.replace("https://docs.google.com/presentation/d/", "");
        _inputValue = _inputValue.split("/")[0];
        if (!templates.includes(_inputValue) && !deletedTemplates.includes(_inputValue)){
            setTemplates([...templates, _inputValue]);
        }
        setInputValue("");
    }

    let rtdbPath = "templates";
    if (docType === "marketAnalytics"){
        rtdbPath = "marketAnalyticsTemplates"
    }
    if (docType === "salesCollateral"){
        rtdbPath = "salesCollateralTemplates"
    }
    const handleDelete = async () => {
        const db = firebaseDB;
        templates.forEach(template => {
            let templatePath = rtdbPath + '/files/' + template;
            let thumbnailPath = rtdbPath + '/templates/' + template;
            let templateRef = ref(db, templatePath);
            let thumbnailRef = ref(db, thumbnailPath);
            set(templateRef, null);
            set(thumbnailRef, null);
            setDeletedTemplates([...deletedTemplates, template])
        })
    }

    const inputValueIsValid = inputValue.startsWith("https://docs.google.com/presentation/d/");

    return <div style={{position: "relative", marginTop: "-3rem"}}>
        <Button simple color="primary" size="sm" onClick={() => setShowModal(true)}>
            Templates löschen?
        </Button>

        <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth={true} maxWidth="md">
            <DialogTitle>
                Templates löschen
            </DialogTitle>
            <DialogContent>
                <Typography>Hierüber kannst Du Templates löschen. Gib dazu einfach den Link zum Template ein.</Typography>

                <Box>
                    {templates.length > 0 && <Typography>Zu löschende Templates:</Typography>}
                    <ul>
                        {templates.map(el => <li key={el} style={{textDecoration: deletedTemplates.includes(el) ? "line-through": "none"}}>
                            {el}
                        </li>)}
                    </ul>

                    <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <CustomInput
                            formControlProps={{
                                style: {
                                    width: "100%"
                                }
                            }}
                            inputProps={{
                                placeholder: "Template-URL",
                                value: inputValue,
                                onChange: e => setInputValue(e.target.value)
                            }}
                        />
                        {inputValueIsValid && <Button size="sm" color="primary" justIcon style={{marginLeft: 14}} onClick={handleAddTemplate}>
                            <Add/>
                        </Button>}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "space-between"}}>
                <Button simple color="danger" onClick={() => setShowModal(false)}>
                    abbrechen
                </Button>

                <Button onClick={handleDelete} size="sm" color="primary">
                    Templates löschen
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}
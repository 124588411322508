import React, {useContext, useState} from 'react'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import DocumentBuilder from '../DocumentBuilder'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import {TemplateLookUp} from "./templateLookup";
import SlideParser from "../SlideParser";
import {DocumentBuilderContext} from "../context";
import {OpCoOPTIONS} from "../../config";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Button from "../../../../components/CustomButtons/Button";
import {Close} from "@material-ui/icons";
import {IntroLauncher} from "../../intro_js";
import {tagIsHeSheGenderfication, tagIsPositionName, tagIsPositionTitle, tagIsAddressBlock} from "../../../../components/helpers";
import {Snackbar} from "@material-ui/core";
import InfoArea from "../../../../components/InfoArea/InfoArea";
import {DocumentLengthProgress} from "../Step1";


const Step2 = React.forwardRef((props, ref) => {
  const [openFile, setOpenFile] = useState(null);
  const { documentData } = useContext(DocumentBuilderContext);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
    sendState: () => {
      return sendState()
    },
    state: {}
  }))
  const sendState = () => {
    return {}
  }
  const isValidated = () => {
    let _isValidated = true;
    documentData.templates.forEach( template => {
      if (template.tags.textTags && template.tags.textTags.filter(tag => (!tagIsPositionName(tag) && !tagIsPositionTitle(tag) && !tagIsAddressBlock(tag))).filter(tag => !tagIsHeSheGenderfication(tag)).some(el => el.value.length < 1)){
        _isValidated = false
      }
      if ( template.tags.selectionTags && template.tags.selectionTags.some(el => el.value.length < 1)){
        _isValidated = false;
      }
    })
    return _isValidated
  }
  return (
    <div id="proposalBuilderContainer">
      <IntroLauncher action="configuratorMain"/>
      <GridContainer justify='center'>
        <GridItem xs={12} md={4} id="templateGallery">
          <DocumentLengthProgress/>
          {documentData.docType && <TemplateLookUp/>}
        </GridItem>
        <GridItem xs={12} md={8} id="buildingArea">
          <CumpolsoryTemplateAlert/>
          <DocumentBuilder onCardClick={file => setOpenFile(file)} />
        </GridItem>
      </GridContainer>

      <FileDialog
        openFile={openFile}
        isOpen={openFile !== null}
        handleOnClose={()=>setOpenFile(null)}
      />
    </div>
  )
})
Step2.displayName = 'Step2'
export default Step2


function CumpolsoryTemplateAlert(){
  const { documentData, updateDocument, templateLibrary } = useContext(DocumentBuilderContext);
  const currentOpcoIndex = OpCoOPTIONS.findIndex( opco => documentData.opco === opco.value);
  let opcoObj = OpCoOPTIONS[currentOpcoIndex];
  let firstSlide = null;
  let lastSlide = null;
  if (opcoObj && opcoObj.compulsoryTemplates){
    firstSlide = _.find(templateLibrary, el => el.id === opcoObj.compulsoryTemplates[0]);
    lastSlide = _.find(templateLibrary, el => el.id === opcoObj.compulsoryTemplates[opcoObj.compulsoryTemplates.length - 1]);
  }
  const insertCoverSlide = () => {
    let newData = {...documentData};
    newData.templates.unshift(firstSlide);
    updateDocument(newData);
  }
  const shiftCoverSlide = () => {
    let newData = {...documentData};
    let index = _.findIndex(newData.templates, el => el.id === firstSlide.id);
    let _firstSlide = newData.templates.splice(index, 1)[0];
    newData.templates.unshift(_firstSlide);
    updateDocument(newData);
  }
  const insertFinalSlide = () => {
    let newData = {...documentData};
    newData.templates.push(lastSlide);
    updateDocument(newData);
  }
  const shiftFinalSlide = () => {
    let newData = {...documentData};
    let index = _.findIndex(newData.templates, el => el.id === lastSlide.id);
    let _lastSlide = newData.templates.splice(index, 1)[0];
    newData.templates.push(_lastSlide);
    updateDocument(newData);
  }

  const wrapperStyle ={
    position: "absolute",
    top: 8,
    left: 0,
    zIndex: 1000,
    minHeight: 0,
    maxHeight: 135,
    overflowX: "hidden",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    width: "100%",
    padding: "0 14px"
  }

  return <div style={wrapperStyle}>
    {firstSlide && !documentData.templates.map(el => el.id).includes(firstSlide.id) && <SnackbarContent
      message={
        <strong>Für {opcoObj.label} muss zwingend <em>{firstSlide.name}</em> als Deckblatt (Folie 1) verwendet werden.
        <br/>Bitte füge diese noch hinzu.<br/><Button color="white" size="sm" onClick={insertCoverSlide}>Jetzt hinzufügen</Button></strong>
      }
      color="warning"
    />}
    {firstSlide && documentData.templates.map(el => el.id).indexOf(firstSlide.id) > 0 && <SnackbarContent
      message={
        <strong>Für {opcoObj.label} muss zwingend <em>{firstSlide.name}</em> als Deckblatt (Folie 1) verwendet werden. Du hast diese an anderer Stelle verwendet.
          <br/>Bitte verschiebe diese an den Anfang. <br/><Button color="white" size="sm" onClick={shiftCoverSlide}>Jetzt verschieben</Button></strong>
      }
      color="warning"
    />}

    {lastSlide && !documentData.templates.map(el => el.id).includes(lastSlide.id) && <SnackbarContent
      message={
        <strong>Für {opcoObj.label} muss zwingend <em>{lastSlide.name}</em> als Abschlussfolie (letzte Folie) verwendet werden.
          <br/>Bitte füge diese noch hinzu. <br/><Button color="white" size="sm" onClick={insertFinalSlide}>Jetzt hinzufügen</Button></strong>
      }
      color="warning"
    />}
    {lastSlide && documentData.templates.map(el => el.id).indexOf(lastSlide.id) !== -1 && documentData.templates.map(el => el.id).indexOf(lastSlide.id) < documentData.templates.length -1 && <SnackbarContent
      message={
        <strong>Für {opcoObj.label} muss zwingend <em>{lastSlide.name}</em> als Abschlussfolie (letzte Folie) verwendet werden. Du hast diese an anderer Stelle verwendet.
          <br/>Bitte verschiebe diese an das Ende. <br/><Button color="white" size="sm" onClick={shiftFinalSlide}>Jetzt verschieben</Button></strong>
      }
      color="warning"
    />}

  </div>
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FileDialog({openFile, isOpen, handleOnClose}){
  return <div>
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOnClose}
      aria-labelledby="openFile-title"
      aria-describedby="openFile-description"
    >
      <DialogTitle id="openFile-title">
        {openFile && openFile.name} <div style={{position: "absolute", top: 14, right: 14, cursor: "pointer" }} onClick={handleOnClose}><Close/></div>
      </DialogTitle>
      <DialogContent>
        <IntroLauncher action={"templateEditor"} style={{margin: 30}}/>
        <DialogContentText id="openFile-description">
          {openFile && openFile.description}
        </DialogContentText>
        <GridContainer>
          <GridItem xs={12} md={8}>
            {openFile && <img src={`data:image/jpg;base64,${openFile.thumbnail}`} width="100%" id="openFilePreviewImg"/>}
          </GridItem>
          {openFile && <GridItem xs={12} md={4}>
            <SlideParser template={openFile}/>
            <div id="saveTemplateTagsButton">
              {Object.keys(openFile.tags).length > 0
                ? <Button onClick={handleOnClose} color="primary" size="sm">speichern</Button>
                : <div>Diese Seite benötigt keine Anpassungen.</div>
              }
            </div>
          </GridItem>}
        </GridContainer>
      </DialogContent>
    </Dialog>
  </div>
}

import {createMuiTheme} from "@material-ui/core/styles";

export const MAX_DOCUMENT_LENGTH = 40;

export const RANDSTAD_THEME = createMuiTheme({
  palette: {
    primary: {
      main: "#2175d9",
    },
    secondary: {
      main: "#cecece",
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#ff9800",
    },
    danger: {
      main: "#f44336",
    },
  },
});

export const OpCoOPTIONS = [
  {
    value: "randstad_automotive",
    label: "Randstad Automotive",
    compulsoryTemplates: [
      "1fRP2ar9T-AWZmlSB8kTshvYozWEXE_8v6humKldAXO0", // first slide
      "1ARm2mLZVvTcnDp7S2jm7wl2JrMm58HP-mn8gD77pfSg" // last_slide
    ],
    isSupported: true
  },
  {
    value: "randstad_deutschland",
    label: "Randstad Deutschland",
    compulsoryTemplates: [
      "19xDEhPlx-lo8C-X7cYSdEV1bfHPswRSYFHWcSj1FVoM", // first slide
      "1lxSfRelkOXkW2aA5H9Ul6h1poorWbNYiHci3ZeCWO30" // last_slide
    ],
    isSupported: true
  },
  {
    value: "randstad_gruppe",
    label: "Randstad Gruppe",
    compulsoryTemplates: [
      "13LOGxWHjD3JoJ79KzjFQKto1F_A8Q18m0e9NMI0dFsw", // first slide
      "19agxONKC7laPYGm1X1wDlupZ7RFlgg-b4QENv7YZKUM" // last_slide
    ],
    isSupported: true
  },
  {
    value: "gulp",
    label: "GULP",
    isSupported: true,
    alert: <p>Die Vorlagen für GULP sind noch nicht fertiggestellt. Bitte geduldige Dich noch ein wenig.</p>
  },
  {
    value: "tempo_team",
    label: "Tempo-Team",
    isSupported : true,
  },
  {
    value: "monster",
    label: "Monster",
    isSupported: false,
    requestable: true,
    alert: <p>Leider bieten wir für Monster derzeit noch keine Vorlagen an.<br/>Bitte melde dich beim Tender Management unter <a href="mailto:tenderservices@randstad.de" target="_blank">tenderservices@randstad.de</a></p>
  },
  {
    value: "twago",
    label: "Twago",
    isSupported : false,
    requestable: true,
    alert: <p>Leider bieten wir für Twago derzeit noch keine Vorlagen an.<br/>Bitte melde dich beim Tender Management unter <a href="mailto:tenderservices@randstad.de" target="_blank">tenderservices@randstad.de</a></p>
  },
  {
    value: "muehlenhoff_by_randstad",
    label: "Randstad RiseSmart",
    compulsoryTemplates: [
      "1MHqy8FXugHw-lp4D4epr2sRbaolIPMvJis_bsz7LFfw", // first slide
      "1dw80UX1GnBeNIodKGnsxDBUgf1GCxP-GkkbHLdRixTQ" // last_slide
    ],
    isSupported: false,
    requestable: true,
    tenderTeamOnly: true,
    alert: <p>Leider bieten wir für Randstad RiseSmart derzeit noch keine Vorlagen an.<br/>Bitte melde dich beim Tender Management unter <a href="mailto:tenderservices@randstad.de" target="_blank">tenderservices@randstad.de</a></p>
  },
  {
    value: "randstad_outsourcing",
    label: "Randstad Outsourcing",
    isSupported : false,
    requestable: true,
    tenderTeamOnly: true,
    //alert: <p>Leider bieten wir für Randstad Outsourcing derzeit noch keine Vorlagen an.<br/>Bitte melde dich beim Tender Management unter <a href="mailto:tenderservices@randstad.de" target="_blank">tenderservices@randstad.de</a></p>
  },
  {
    value: "randstad_sourceright",
    label: "Randstad Sourceright",
    isSupported: false,
    requestable: true,
    tenderTeamOnly: true,
    //tenderTeamOnly: true,
    //alert: <p>Leider bieten wir für Randstad Sourceright derzeit noch keine Vorlagen an.<br/>Bitte melde dich beim Tender Management unter <a href="mailto:tenderservices@randstad.de" target="_blank">tenderservices@randstad.de</a></p>
  },
];

export const DEAL_TEAM_ROLES = [
  'Ansprechpartner1',
  'Ansprechpartner2',
  'Ansprechpartner3',
  'Account Specialist',
  'Account Manager',
  'Branch Manager',
  'Process Manager',
  'Key Account Manager',
  'District Manager',
  'Tender Manager',
  'Sales Lead',
  'Operations Lead',
  'On-Site Manager',
  'Personalberater',
  'Projektmanager',
  'Commercial Manager',
  'Consultant',
  'Recruiter',
  'MSP Consultant',
  'Kunde HR Manager',
  'Recruitment Business Partner',
  'Sourcing Recruiter',
  'Recruitment Consultant',
  'Recruiting Consultant',
  'Single Point of Contact',
  'Sales Consultant',
  'Business Unit Manager',
  'Service Manager',
  'Produktionsleiter'

];

export const APP_SCRIPT_DEPLOYMENT_ID = "AKfycbxZeJom6nxaUDqc0gdMM4ZOBLaRgsRo52UPbyzLDtD8QhsOn3-LSnTCr1JY8e5T0rOS";
export const AppScriptSecret = "JthTRyDrX8HGa67dzAhQfCbk3gSzCBTfMcaApEQY-GcLxTDHkyK8MtJQ4xgr8ktb7EihA6q6MdFaiqpsE-S8hkKgbzJSk7YtShTrB3txfDtHte9jNoDmtLoyDc-jP794pcFPqLSzqoP6op7oBjmr6H9SEbmspr9nkCX";
export const CloudFunctionAuthSecret = "z66CS5pcQkTktza6Yq3YeEdzRXerEKfX85jmAFMQ-N8bs5ctr3SL$9&bB9ydTyofqRQ@it7BYJxB@sSrz-?zYqotqPF4gTr$DiHeeq8MyRo3n#t6QxTqe5Rh?N-M?R7ExPzhxjq6axopFS4HdRP88HSegDoSYbA$$?N";

export const env = "prod";

export const GCP_PROJECT_ID = env === "prod" ? "derd-proptool-prd-499f" : "derd-proptool-dev-31a0";

export const MARKET_ANALYTICS_USERS = [
    "annegret.ohlmeyer@randstad.de",
    "ulf.froehlich@randstad.de",
    "shezan.kazi@gulp.de"
]

export const marketAnalyticsProfessionTags = [
  "{{QUALI_LANG}}",
  "{{ISCO_QUALI}}",
  "{{LISTE_BEZEICHNUNGEN_QUALI}}",
]


export const ADMINS = [
    "svenja.bauer@randstad.de",
    "shezan.kazi@gulp.de",
    "sirkka.mentlein@randstad.de",
    "martina.kapfhammer@randstad.de"
]

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import customSelectStyles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

const useSelectStyles = makeStyles(customSelectStyles);


export default function AutoCompleteAsync({
                                            url,
                                            name,
                                            labelKey,
                                            handleChange,
                                            optionRenderer,
                                            filterOptions,
                                            groupBy,
                                            renderGroup,
                                            autoComplete,
                                            customAppendedOptions,
                                            style
                                          }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(null);

  if (!filterOptions) {
    filterOptions = (options) => options.concat(customAppendedOptions)
  }

  let delayTimer;
  const handleInputChange = (query) => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      query && query.length > 0 && runSearch(query)
    }, 1000);
  }

  const runSearch = (query) => {
    setLoading(true);
    setOptions([]);
    axios({
      url,
      // headers: API_HEADERS,
      params: {query: query}
    }).then(r => {
      let data = r.data;
      setOptions(r.data);
      setLoading(false);
    }).catch(err => console.log(err))
  }

  return (
    <Autocomplete
      autoComplete={autoComplete}
      style={style}
      id={name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={labelKey}
      groupBy={groupBy}
      renderGroup={renderGroup}
      onChange={(e, val) => handleChange(val)}
      options={options}
      noOptionsText="Keine Treffer"
      loading={loading}
      loadingText="bitte warten..."
      renderOption={optionRenderer}
      renderInput={(params) => (
        <TextField
          {...params}
          label={name}
          InputProps={{
            value: "",
            onChange: e => handleInputChange(e.target.value),
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
AutoCompleteAsync.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  handleChange: PropTypes.func,
  optionRenderer: PropTypes.func,
  filterOptions: PropTypes.func,
  groupBy: PropTypes.func,
  renderGroup: PropTypes.func,
  minQueryLength: PropTypes.number,
  autoComplete: PropTypes.bool,
  customAppendedOptions: PropTypes.array, // array of custom options that are appended to results
}
AutoCompleteAsync.defaultProps = {
  minQueryLength: 3,
  autoComplete: false
}
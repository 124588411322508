import React, {useContext, useEffect, useState} from "react";
import {Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";
import {DocumentBuilderContext} from "../context";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {Check} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import {dangerColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {tagIsPositionName, tagIsPositionTitle, tagIsAddressBlock} from "../../../../components/helpers";


const useStyles = makeStyles({
    ...checkBoxStyles,
    ...customSelectStyles,
});

export const getDefaults = (newTags, templates, docType, district) => {
    Object.keys(newTags).forEach(newTagCat => {
        newTags[newTagCat].forEach(newTag => {
            if (newTag.type === "text" && newTag.value === "") {
                if (docType === "marketAnalytics") {
                    if (newTag.tag.toLowerCase().replace("{{ ", "{{").startsWith("{{standort")){
                        newTag.value = district.kreisname
                    }
                }
                _.filter(templates, el => el.tags !== undefined).reverse().forEach(_template => {
                    _template.tags.textTags && _template.tags.textTags.find(_tag => {
                        if (_tag.tag.toLowerCase().replace(/ /g, "") === newTag.tag.toLowerCase().replace(/ /g, "")) {
                            newTag.value = _tag.value;
                        }
                    })
                })
            } else if (newTag.type === "selection" && newTag.value === "") {
                let tag_label = newTag.tag.match(/<\?([^<>]+)\?>/)[0];
                let value_tag_matches = newTag.value.match(/({{[^{{%]+}})/);
                let value_tag = value_tag_matches ? value_tag_matches[0] : null;
                _.filter(templates, el => el.tags !== undefined).reverse().forEach(_template => {
                    _template.tags.selectionTags && _template.tags.selectionTags.find(_tag => {
                        if (newTag.tag.includes(value_tag)) {
                            let optionValueIndex = newTag.options.findIndex(el => el.includes(value_tag));
                            newTag.value = newTag.options[optionValueIndex];
                        } else {
                            if (newTag.tag === _tag.tag) {
                                newTag.value = _tag.value
                            } else {
                                let _val = newTag.options.find(el => el.match(/({{[^{{%]+}})/) === null)
                                // in some scenarios no match is found, therefore we need to provide a default value.
                                // this can happen, if both option contain a value tag.
                                newTag.value = _val ? _val : "";
                            }
                        }
                    })
                })
            } else if (newTag.type === "boolean") {
                _.filter(templates, el => el.tags !== undefined).reverse().forEach(_template => {
                    _template.tags.booleans && _template.tags.booleans.find(_tag => {
                        if (_tag.tag.toLowerCase().replace(/ /g, "") === newTag.tag.toLowerCase().replace(/ /g, "")) {
                            newTag.value = _tag.value;
                        }
                    })
                })
            }
        })
    })
    return newTags
}


export default function SlideParser({template}) {
    const classes = useStyles();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    const [loadingTags, setLoadingTags] = useState(true);
    const [tagError, setTagError] = useState(false);
    let isMarketAnalyticsMultipleProfessionsSlide = false;
    if (template.description.includes("#multipleProfessions")){
        isMarketAnalyticsMultipleProfessionsSlide = true
    }

    const labelRegex = /<\?([^<>]+)\?>/;

    useEffect(() => {
        updateTags()
    }, [])

    const updateTags = () => {
        let data = getDefaults(template.tags, documentData.templates, documentData.docType, documentData.mainDistrict);
        let newData = {...documentData};
        let templateIndex = newData.templates.findIndex(item => item.id === template.id);
        newData.templates[templateIndex].tags = data;
        updateDocument(newData);
        setLoadingTags(false)
    }

    const handleInputChange = (val, tag) => {
        let newData = {...documentData};
        let templateIndex = newData.templates.findIndex(el => el.id === template.id);
        let tagIndex = newData.templates[templateIndex].tags.textTags.findIndex(el => el.tag === tag.tag);
        newData.templates[templateIndex].tags.textTags[tagIndex].value = val;
        newData.templates[templateIndex].tags.selectionTags && newData.templates[templateIndex].tags.selectionTags.forEach(selectionTag => {
            if (selectionTag.tag.includes(tag.tag)) {
                selectionTag.variableValue = val;
            }
        });
        updateDocument(newData);
    }

    const handleSelectChange = (val, tag) => {
        // check if a tag contains a text_value such as {{ KUNDE_NAME }}
        let tag_label = tag.tag.match(/<\?([^<>]+)\?>/)[0];
        let value_tag_matches = val.match(/({{[^{{%]+}})/);
        let value_tag = value_tag_matches ? value_tag_matches[0] : null;
        let newData = {...documentData};
        // selects fields are always unique/distinct for on specific slide, so we need to grab this specific slide
        // meaning if two select fields with identical labels are on one page, then only the first will be rendered
        // the second one thus needs to be populated with the select value of the first
        let templateIndex = newData.templates.findIndex(el => el.id === template.id);
        // only iterate over selection tags with identical label
        newData.templates[templateIndex].tags.selectionTags.filter(el => {
            return tag_label === el.tag.match(/<\?([^<>]+)\?>/)[0]
        }).forEach(selTag => {
            if (selTag.tag.includes(value_tag)) {
                let optionValueIndex = selTag.options.findIndex(el => el.includes(value_tag));
                selTag.value = selTag.options[optionValueIndex];
            } else {
                if (selTag.tag === tag.tag) {
                    selTag.value = val
                } else {
                    selTag.value = selTag.options.find(el => el.match(/({{[^{{%]+}})/) === null);
                }
            }
        });
        updateDocument(newData);
    }

    const handleBooleanChange = (val, tag) => {
        let newData = {...documentData};
        let templateIndex = newData.templates.findIndex(el => el.id === template.id);
        let tagIndex = newData.templates[templateIndex].tags.booleans.findIndex(el => el.tag === tag.tag);
        newData.templates[templateIndex].tags.booleans[tagIndex].value = val;
        updateDocument(newData);
    }

    return <div id="templateTagsEditor">

        {tagError && <h5 style={{color: dangerColor[0]}}><strong>FEHLER BEIM LESEN DER TAGS. BITTE ÜBERPRÜFE DIE
            VORLAGE.</strong></h5>}

        {loadingTags && <LoadingSpinner/>}
        <div>
            {isMarketAnalyticsMultipleProfessionsSlide && <div>
                <p>Diese Folie wird {documentData.professions.length}x erstellt, da {documentData.professions.length} ISCO-Berufe ausgewählt wurden.</p>
            </div>}
            {/* TEXT TAGS */}
            {template.tags.textTags && template.tags.textTags.map((tag, key) => {
                let normalizedTag = tag.tag.replace(/ /g, "").toLowerCase();
                let isHeSheTag = normalizedTag.startsWith("{{#he_she") || normalizedTag.startsWith("{{#she_he");
                let isHisHerTag = normalizedTag.startsWith("{{#his_her") || normalizedTag.startsWith("{{#her_his");
                if (!isHeSheTag && !isHisHerTag) {
                    if (!tagIsPositionName(tag) && !tagIsPositionTitle(tag) && !tagIsAddressBlock(tag) && !isMarketAnalyticsMultipleProfessionsSlide) {
                        return <div key={key}>
                            <CustomInput
                                labelText={<span>
                                    {tag.tag.replace("{{", "").replace("}}", "")}
                                  </span>}
                                id={`template_text_tag-${tag.tag}_${key}`}
                                formControlProps={{
                                    fullWidth: true,
                                    className: "introjs__text_tag"
                                }}
                                inputProps={{
                                    onChange: e => handleInputChange(e.target.value, tag),
                                    value: tag.value
                                }}
                            />
                        </div>
                    }
                }
            })}

            {/* BOOLEAN TAGS */}
            {template.tags.booleans && template.tags.booleans.filter(tag => !tag.tag.includes("__GENDER_F")).map((tag, key) => {
                return <div key={key}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                onClick={e => handleBooleanChange(e.target.checked, tag)}
                                checked={tag.value}
                                checkedIcon={<Check className={classes.checkedIcon}/>}
                                icon={<Check className={classes.uncheckedIcon}/>}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}
                            />
                        }
                        classes={{label: classes.label}}
                        label={tag.tag.replace("$", "")}
                    />
                </div>
            })}

            {/* SELECTION TAGS */}
            {template.tags.selectionTags && _.uniqBy(template.tags.selectionTags, el => el.tag.match(/<\?([^<>]+)\?>/)[0]).map((tag, key) => (
                <div key={key}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor={tag.tag + "_select"} className={classes.selectLabel}>
                            {labelRegex.test(tag.tag) ? tag.tag.match(labelRegex)[0].replace("<?", "").replace("?>", "") + " wählen..." : tag.tag + " wählen..."}
                        </InputLabel>

                        <Select
                            MenuProps={{className: classes.selectMenu}}
                            classes={{select: classes.select}}
                            value={tag.value}
                            onChange={e => handleSelectChange(e.target.value, tag)}
                            inputProps={{name: tag.tag + "Select", id: tag.tag + "_select"}}
                        >
                            <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                                {labelRegex.test(tag.tag) ? tag.tag.match(labelRegex)[0].replace("<?", "").replace("?>", "") : tag.tag} wählen...
                            </MenuItem>

                            {tag.options.map(option => <MenuItem key={option} classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }} value={option}>
                                {option.split("<?")[0]}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
            ))}
        </div>
    </div>
}


const LoadingSpinner = () => <div
    style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 400}}>
    <CircularProgress/>
    <p style={{marginTop: 40}}>Slide wird analysiert... Bitte warten...</p>
</div>

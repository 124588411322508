import introJs from "intro.js";
import {Help} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {boxShadow, grayColor} from "../../assets/jss/material-dashboard-pro-react";
import {useContext, useEffect, useState} from "react";
import {firebaseDB} from "../../firebase/config";
import {ref, set} from "firebase/database";
import {AuthContext} from "../../firebase/context";
import {DocumentBuilderContext} from "./WizardSteps/context";


export default function startIntroJs(action, user){
  if (action === "welcome+"){
    initializeIntroJs(action, user).setOptions({
      steps: welcome__steps.concat(pageOneStepOne__steps),
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "welcome"){
    initializeIntroJs(action, user).setOptions({
      steps: welcome__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "pageOneStepOne"){
    initializeIntroJs(action, user).setOptions({
      steps: pageOneStepOne__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "pageOneStepOneNonTender"){
    initializeIntroJs(action, user).setOptions({
      steps: pageOneStepOneNonTender__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "pageOneStepTwo"){
    initializeIntroJs(action, user).setOptions({
      steps: pageOneStepTwo__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "pageOneStepThree"){
    initializeIntroJs(action, user).setOptions({
      steps: pageOneStepThree__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "clientFolderDialog"){
    initializeIntroJs(action, user).setOptions({
      steps: clientFolderDialog__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "configuratorMain"){
    initializeIntroJs(action, user).setOptions({
      steps: configuratorMain__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "templateList"){
    initializeIntroJs(action, user).setOptions({
      steps: templateList__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "buildingArea"){
    initializeIntroJs(action, user).setOptions({
      steps: buildingArea__steps,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "buildingAreaEmpty"){
    initializeIntroJs(action, user).setOptions({
      steps: buildingArea__steps__empty,
      ...defaultIntroJsOptions
    }).start();
  }
  if (action === "templateEditor"){
    initializeIntroJs(action, user).setOptions({
      steps: templateEditor__steps,
      ...defaultIntroJsOptions
    }).start();
  }
};

const initializeIntroJs = (action, user) =>{
  return introJs().onchange(
    t => handleIntroChange(t)
  ).oncomplete(
    () => handleIntroOncomplete(action, user)
  );
}

const handleIntroChange = (target) => {
  //console.log(target)
}

const handleIntroOncomplete = (action, user) => {
  const userEncoded = user.email.replace(/\./g, "__dot__").replace(/@/g, "__at__");
  updateIntroJsStepStatus(action, userEncoded, "completed")
}

const updateIntroJsStepStatus = (action, userEncoded, introJsSettings) => {
  const db = firebaseDB;
  let introJsRef = ref(db, `users/${userEncoded}/introJs/${action}/`);
  set(introJsRef, introJsSettings)
}

const welcome__steps = [
  {
    title: "Herzlich willkommen...",
    //element: "#wizardContainer",
    intro: `<p>im SalesPekka, dem Randstad-Tool zum Erstellen von Angeboten! 
            <br/><br/>Dies gilt für alle Opcos der Randstad Gruppe.
            <br/><br/>Das Ergebnis ist eine Angebotsdatei entsprechend der Designvorgaben, die Du auf Google Drive in einer einheitlichen Ablagestruktur individuell bearbeiten kannst. 
            <br/><br/>
            Du befindest Dich gerade in der Hilfe-Funktion. Mit den <strong>Pfeil-Tasten</strong> deiner Tastatur oder den Buttons unten links und rechts kannst Du navigieren. Um die Hilfe-Funktion zu verlassen, klicke einfach das Kreuzchen oben rechts oder die ESC-Taste.</p>`,
    tooltipClass: "wide"
  },
  {
    title: "Kopfzeile",
    element: "#wizardHeader",
    intro: "Hier sieht Du den Namen deiner Ausschreibung bzw. deines Angebots. Dazu musst Du aber zunächst einen Namen wählen. Wie das geht, erfährst Du gleich in Schritt 1."
  },
  {
    title: "Navigationsleiste",
    element: "#wizardNavigation",
    intro: "Die Navigationsleiste zeigt Dir an, in welchem Reiter bzw. Teil des Konfigurators Du Dich gerade befindest. Du kannst Tabs in dieser Leiste allerdings nicht anklicken, nutze dazu einfach die Navigations-Buttons unten."
  },
  {
    title: "Navigations-Button",
    element: ".introjs__nextButton",
    intro: "Klick auf diesen Button, um zum nächsten Reiter bzw. Teil des Konfigurators zu gelangen. Sollte nichts passieren, hast Du vermutlich etwas vergessen.",
  }
];

const pageOneStepOne__steps = [
  {
    title: "Schritt 1",
    element: "#pageOneStepOne",
    intro: "Legen wir los. Im ersten Schritt, musst Du einen Kunden auswählen.",
  },
  {
    title: "Überschrift",
    element: "#pageOneStepOneHeader",
    intro: "Anhand der Überschrift erkennst Du, in welchem Schritt Du Dich befindest. Ist die Überschrift grau, dann ist der Schritt noch nicht abgeschlossen."
  },
  {
    title: "Kunde suchen",
    element: "#pageOneStepOneClientSearchInput",
    intro: "Du kannst hier nach dem Kunden suchen, für den Du das Angebot erstellen willst."
  },
  {
    title: "Kunde auswählen",
    element: "#pageOneStepOneClientList",
    intro: 'Wenn Du den richtigen Kunden gefunden hast, kannst Du ihn hier mit einem Klick einfach auswählen.',
  },
  {
    title: "Kunde neu erstellen",
    element: "#pageOneStepOneClientList",
    intro: 'Solltest Du nichts passendes finden, kannst Du auch einen Kunden <em>"neu erstellen"</em>. Probiere es aus durch KLICK auf das runde blaue Plus neben dem Text "Kunde neu erstellen" - dort findest Du auch weitere Anleitungen mit dem Fragezeichen',
  },
  {
    title: "Los geht's!",
    intro: "<p>Jetzt bist Du erstmal Start-klar! <br/>Solltest Du bei den weiteren Schritten Hilfe benötigen, dann halte einfach nach den <strong>Fragezeichen</strong> Ausschau. Damit kannst Du diese Hilfe jederzeit öffnen.</p>"
  },
];
const pageOneStepOneNonTender__steps = [
  {
    title: "Schritt 1",
    element: "#pageOneStepOne",
    intro: "Legen wir los. Im ersten Schritt, musst Du einen Namen für Deine Datei auswählen.",
  },
  {
    title: "Überschrift",
    element: "#pageOneStepOneHeader",
    intro: "Anhand der Überschrift erkennst Du, in welchem Schritt Du Dich befindest. Ist die Überschrift grau, dann ist der Schritt noch nicht abgeschlossen."
  },
  {
    title: "Dateinamen festlegen",
    element: "#pageOneStepOneFilenameInput",
    intro: "Hier legst Du fest, wie Deine Datei am Ende heißen soll. Der Dateiname sollte idealerweise länger als 3 Zeichen sein."
  },
  {
    title: "Los geht's!",
    intro: "<p>Jetzt bist Du erstmal Start-klar! <br/>Solltest Du bei den weiteren Schritten Hilfe benötigen, dann halte einfach nach den <strong>Fragezeichen</strong> Ausschau. Damit kannst Du diese Hilfe jederzeit öffnen.</p>"
  },
];
const pageOneStepTwo__steps = [
  {
    title: "Schritt 2",
    element: "#pageOneStepTwo",
    intro: "Machen wir weiter. In diesem Schritt musst Du die OpCo wählen, für die das Angebot erstellt wird. Das wirkt sich vor allem auf Design und verfügbare Inhalte aus.<br/><br/><small>Derzeit werden noch nicht alle OpCos unterstützt.<br/>Sollte Deine OpCo fehlen, wende Dich einfach an <a href='mailto:tenderservices@randstad.de' target='_blank'>tenderservices@randstad.de</a></small>."
  },
  {
    title: "Schritt 1 abgeschlossen",
    element: "#pageOneStepOneHeader",
    intro: "Da Schritt 1 jetzt abgeschlossen ist, ist die Überschrift jetzt nicht mehr grau sondern blau. Aber das nur nebenbei..."
  },
  {
    title: "OpCo wählen",
    element: "#opcoSelect",
    intro: "Hier wählst Du zunächst die entsprechende OpCo aus. Klick dazu einfach auf das Pfeilchen, damit sich das Dropdown mit den Auswahlmöglichkeiten öffnet."
  },
  /* TODO: add this once it makes sense, i.e. an api for requesting user info exists
  {
    title: "Deal-Team festlegen",
    element: "#dealTeamBuilder",
    intro: 'Hier legst Du fest, wer an der Ausschreibung/Tender beteiligt ist. Du kannst die Dokumente auch im Nachgang freigeben. Derzeit fehlen für das Deal-Team noch ein paar Funktionen, daher genügt in der Regel Deine Email und die der beteiligten Kollegen.<br/><br/>Klicke einfach auf den blauen Button, um neue Mitglieder einzutragen.<br/><br/><small>Demnächst kommen hier noch nützliche Features dazu.</small>',
  },*/
  {
    title: "Fertig mit Schritt 2!",
    intro: "Weiter geht's mit Schritt 3 und dann kannst Du auch schon mit der Konfiguration loslegen!"
  },
];
const pageOneStepThree__steps = [
  {
    title: "Schritt 3",
    element: "#pageOneStepThree",
    intro: `Machen wir weiter. In diesem Schritt kannst Du die Standard-Module wählen.
        <br/><br/>Ein Standard-Modul besteht aus einer Anzahl bestimmter Seiten, die ein bestimmtes Thema behandeln. Wenn Du nicht alle Seiten nutzen willst, kannst die einzelne Seiten im Konfigurator löschen.
        <br/><br/><strong>Dieser Schritt ist optional</strong>, Du kannst also einfach fortfahren...
        <br/><br/>Wenn du kein Modul auswählst wird legiglich die Angebotsvorlage im Design der ausgewählten Opcos erstellt.`,
  },
  {
    title: "Module auswählen",
    element: "#presetModulePicker",
    intro: "Um ein Modul auszuwählen, klick einfach auf den Namen oder die Checkbox. Ein Modul ist dann ausgewählt, wenn die Checkbox ein blaues Häkchen hat."
  },
  {
    title: "Auf zum Konfigurator!",
    element: "#navigationNextButton",
    intro: "Du hast jetzt die allgemeinen Angaben abgeschlossen. Im nächsten Schritt baust Du Dir Dein Dokument im Konfigurator! &#128077;<br/><br/><small>Klicke dazu auf den 'WEITER'-Button unten rechts.</small>"
  }
];

const clientFolderDialog__steps = [
  {
    title: "Ordnung muss sein...",
    intro: "Unser Group Tender Management legt für jeden Kunden sorgfältige Ordner-Strukturen an. Damit alles einheitlich bleibt, übernimmt dieses Tool die Erstellung dieser Ordner für Dich.",
  },
  {
    title: "Ordner-Struktur",
    element: "#clientYearFolderStructure",
    intro: "Hier kannst Du eine bestehende Ausschreibung auswählen. Wenn Du hier auf einen Unterordner (also nicht eine der Jahreszahlen, sondern die darunter eingerückten Ordner) klickst, wird Dein Angebot dort abgelegt.<br/><br/><small>(Eventuell sind dort noch keine Ordner.)</small>"
  },
  {
    title: "Neu erstellen",
    element: "#createNewClientFolderStructure",
    intro: "Sollte keine passende Ausschreibung vorhanden sein, kannst Du natürlich auch eine neue Ausschreibung anlegen."
  },
  {
    title: "Name der Ausschreibung",
    element: "#tenderNameInput",
    intro: "Namen für Ausschreibungen werden nach einem bestimmten Muster erstellt. Der Platzhalter in diesem Feld ist quasi das Präfix für den Tender-Namen: Jahr, Monat Kundenname. Dahinter kannst du den Titel, wie z.B. die gesuchte Dienstleistung der Ausschreibung, hinzufügen. Das könnte dann so aussehen: '2201 Musterkunde AÜ gewerblich bundesweit'"
  },
  {
    title: "Neuen Tender erstellen",
    element: "#createNewClientFolderStructure_button",
    intro: "Nachdem Du den Namen für deinen Tender sorgfältig gewählt hast, kannst Du hier den Ausschreibungsordner erstellen. Das dauert einen Moment, also gedulde Dich bitte kurz."
  },
  {
    title: "Weiter geht's!",
    intro: "Super! Du hast jetzt einen Tender gewählt oder neu erstellt. Auf zum nächsten Schritt: Schritt 2!"
  },
];

const configuratorMain__steps = [
  {
    title: "Los geht's!",
    element: "#proposalBuilderContainer",
    intro: "Du befindest Dich jetzt im Konfigurator, dem eigentlichen Herzstück von SalesPekka."
  },
  {
    title: "Ausschreibungs-Ordner",
    element: "#tenderFolderHyperlink",
    intro: "Hier sieht Du jetzt den Namen deiner Ausschreibung bzw. deines Angebots. Klicke darauf, um den fertig angelegten Ordner in Google Drive zu öffnen."
  },
  {
    title: "Schritt 1: Vorlagen",
    element: "#templateGallery",
    intro: "Links findest Du die Vorlagen-Galerie.",
  },
  {
    title: "Schritt 2: Dokument konfigurieren",
    element: "#buildingArea",
    intro: "Rechts findest Du die eigentliche Arbeitsfläche.",
  },
  {
    title: "Dokument erstellen",
    element: "#buildDocumentButton",
    intro: 'Sobald Du alle Folien fertig bearbeitet hast (also alle ein grünes Häkchen haben), erscheint hier ein Button "Dokument erstellen". <br/><br/><small>Die Generierung deines Dokuments kann ein paar Minuten dauern. Du bekommst aber eine Email, sobald es fertig ist.</small>'
  },
  {
    title: "Und nun?",
    intro: "Lass uns mit Schritt 1 anfangen und zunächst die Vorlagen-Galerie näher ansehen..."
  },
];

const templateList__steps = [
  {
    title: "Vorlagen-Galerie",
    element: "#templateLookup",
    intro: "Hier sieht Du alle verfügbaren Vorlagen, deiner ausgewählten OpCo, die Du für Deine Ausschreibung/Dein Angebot verwenden kannst."
  },
  {
    title: "Vorlage suchen",
    element: "#templateSearchInput",
    intro: 'Du kannst nach Vorlagen suchen. Vermutlich kennst Du den genauen Namen nicht immer, probiere es daher einfach mal mit einem Stichwort wie "MSP" oder "Personalvermittlung".'
  },
  {
    title: "Vorlage ansehen",
    element: ".templateLookup_itemName",
    intro: 'Mit einem Klick auf den Namen der Vorlage kannst Du Dir eine Vorschau anzeigen lassen. Wenn Du eine Vorlage bereits gesichtet hast, taucht rechts vom Namen ein "Auge" auf - damit Du den Überblick nicht verlierst.'
  },
  {
    title: "Vorlage auswählen",
    element: ".templateLookup_addItem",
    intro: 'Wenn Du die richtige Vorlage gefunden hast, kannst Du sie hier mit einem Klick auf das <strong>blaue Plus</strong> einfach auswählen - wenn eine Vorlage bereits ausgewählt wurde, siehst du kein blaues Plus, sondern ein grünes Häkchen.<br/><br/><small>Solltest Du nichts passendes finden, kannst Du auch eine neue Vorlage <em>"neu erstellen"</em> lassen. Klicke dazu einfach auf das Listenelement <em>Neu erstellen: ...</em></small>',
  },
];

const buildingArea__steps__empty = [
  {
    title: "Arbeitsfläche",
    element: "#buildingArea",
    intro: "Das ist Deine Arbeitsfläche. Hier kannst Du einzelne Folien verschieben (ganz einfach per Drag-and-Drop) und löschen, sowie die Inhalte der Folien bearbeiten."
  },
  {
    title: "Ansicht umschalten",
    element: "#gridSizeToggler",
    intro: 'Vor allem bei größeren Dokumenten kann es nützlich sein, auf einen 6x6 Grid umzuschalten, damit man einfach mehr sieht. Mit diesem "Button" schaltest Du zwischen großen und kleinen Icons hin und her.'
  },
  {
    title: "Folien",
    element: "#thumbnailNo_0",
    intro: 'Du hast noch keine Folien ausgewählt. Wähle jetzt eine Folie aus und starte diesen Hilfe-Schritt erneut.'
  }
];

const buildingArea__steps = [
  {
    title: "Arbeitsfläche",
    element: "#buildingArea",
    intro: "Das ist Deine Arbeitsfläche. Hier kannst Du einzelne Folien verschieben (ganz einfach per Drag-and-Drop) und löschen, sowie die Inhalte der Folien bearbeiten."
  },
  {
    title: "Ansicht umschalten",
    element: "#gridSizeToggler",
    intro: 'Vor allem bei größeren Dokumenten kann es nützlich sein, auf einen 6x6 Grid umzuschalten, damit man einfach mehr sieht. Mit diesem "Button" schaltest Du zwischen großen und kleinen Icons hin und her.'
  },
  {
    title: "Folien",
    element: "#thumbnailNo_0",
    intro: 'Hier siehst Du eine Folie. Eine Folie besteht aus mehreren Teilen....'
  },
  {
    title: "Folie löschen 1/2",
    element: ".thumbnail_deleteIcon",
    intro: 'Fangen wir oben an... Mit diesem Minus-Button kannst Du die Folie löschen. Damit das nicht aus Versehen geschieht, musst Du die Aktion bestätigen und das geht wie folgt:'
  },
  {
    title: "Folie löschen 2/2",
    element: ".thumbnail_deleteIcon",
    intro: '<ol><li>Klicke auf den Button.</li><li>Jetzt erscheint ein roter Mülleimer. Klicke innerhalb von 2 Sekunden darauf, um die Folie zu löschen.</li></ol>'
  },
  {
    title: "Folie bearbeiten 1/2",
    element: ".thumbnail_editIcon",
    intro: 'Sofern für eine Folie noch Anpassungen erforderlich sind, siehst Du hier einen grauen Stift.<br/><br/>Wenn Du stattdessen ein grünes Häkchen siehst, ist die Folie fertiggestellt.'
  },
  {
    title: "Folie bearbeiten 2/2",
    element: ".thumbnail_editIcon",
    intro: 'In jedem Fall kannst Du auf dieses Icon klicken und damit die Folie zur genaueren Betrachtung und Bearbeitung öffnen.'
  },
  {
    title: "Vorschaubild",
    element: ".thumbnail_img",
    intro: 'Das ist eine Vorschau der Folie. Bedenke, dass diese unter Umständen nicht ganz aktuell sein könnte - das macht aber i.d.R. nichts!'
  },
  {
    title: "Fertig!",
    intro: 'Soviel dazu. Als nächstes kannst Du eine der Folien öffnen und bearbeiten!'
  },
];

const templateEditor__steps = [
  {
    title: "Folie anpassen",
    intro: "Hier kannst Du Deine Folie bearbeiten...",
  },
  {
    title: "Aktuelle Vorlage",
    element: "#openFilePreviewImg",
    intro: "Das ist Deine Vorlage. Alle Textbausteine in eckigen Klammern kannst Du bearbeiten.<br/><br/><small>Wenn Du Dich näher für die Logik der Vorlagen interessierst, kannst Du Dir <a href='https://jinja2docs.readthedocs.io/en/stable/' target='_blank'>Jinja2</a> mal ansehen.</small>",
  },
  {
    title: "Text-Bausteine 1/4",
    element: "#templateTagsEditor",
    intro: "Hier findest Du alle Textbausteine zur Bearbeitung. Es gibt im Wesentlichen 3 Bausteine:",
  },
  {
    title: "Text-Bausteine 2/4",
    element: ".introjs__text_tag",
    intro: `<strong>1. Text-Felder</strong>:<br/>Das hier ist bspw. ein Text-Feld. Hier kannst Du einen Freitext eintragen, wie bspw. den Namen des Kunden.
            <br/>
            <strong>BEISPIEL</strong>:
            <br/>In der Vorlage sieht der Text-Baustein bspw. so aus:<br/><code>{{KUNDE_NAME}}</code>
            <br/>Das dazugehörige Text-Feld wäre dann:
            <br/><im
    `,
  },
  {
    title: "Text-Bausteine 3/4",
    element: "#templateTagsEditor",
    intro: '<strong>2. Wahr/Falsch-Felder</strong>:<br/>Hier siehst Du Checkboxen, die Du auswählen kannst, um weitere Textteile in deinem Angebot zu erhalten, wenn du einem Kunden bspw. eine Zusatzdienstleistung anzubieten wie "WePlan".',
  },
  {
    title: "Wichtig!",
    intro: 'Einmal definierte Textbausteine werden auf alle Folgefolien übertragen. Du kannst diese aber an jeder Stelle individuell ändern. Bspw: Auf dem Deckblatt "Musterfirma" GmbH und auf den Folgefolien dann nur noch "Musterfirma".',
  },
  {
    title: "Text-Bausteine 4/4",
    element: "#templateTagsEditor",
    intro: '<strong>3. Dropdown-Felder</strong>:<br/>Hier kannst Du aus einem Dropdown bzw. einer Liste einen Eintrag auswählen.',
  },
  {
    title: "Änderungen speichern",
    element: "#saveTemplateTagsButton",
    intro: 'Deine Änderungen werden in Echtzeit gespeichert. Trotzdem kannst Du mit diesem Button die aktuelle Folie schließen. Übrigens werden alle Angaben, die Du schon einmal gemacht hast bei allen weiteren Folien übernommen &#9996;.',
  },
  {
    title: "Wichtig!",
    intro: "Du kannst das Dokument erst erstellen wenn jede Seite ein grünes Häkchen hat!"
  },
  {
    title: "Et voilà!",
    intro: `Jetzt weißt Du, wie man Folien anpasst. Mach das für alle Folien, klicke auf den "Dokument generieren"-Button unten rechts und "watch the magic happen"!
            <br/><br/><small>Sollte der "Dokument generieren"-Button fehlen und stattdessen ein orangener Hinweis zu sehen sein, dass noch nicht alle Tags ausgefüllt sind, überprüfe bitte nochmal, ob alle Folien ein grünes Häkchen haben.</small>`
  },
];

// DEFAULT OPTIONS
const defaultIntroJsOptions = {
  nextLabel: "Weiter",
  prevLabel: "Zurück",
  doneLabel: "Fertig",
  disableInteraction: true,
  exitOnOverlayClick: false
}

// COMPONENTS
const useIntroLauncherStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 10,
    zIndex: 999,
    cursor: "pointer",
    color: grayColor[4],
    height: "1.25rem",
    width: "1.25rem",
    borderRadius: "50%",
    "&:hover": {
      color: "#fdd835",
      ...boxShadow,
    }
  },
  pending: {
    color: "#fdd835",
  },
  icon: {
    fontSize: "1.25rem"
  }
}));
export function IntroLauncher({action, isLeft, style}){
  const {user} = useContext(AuthContext);
  const { updateUserProfile, userProfile } = useContext(DocumentBuilderContext);
  const [iconState, setIconState] = useState(null);
  const classes = useIntroLauncherStyles();

  useEffect( () => {
    // check if intro js has been completed
    if (userProfile && userProfile.introJs){
      if (userProfile.introJs[action]){
        setIconState(userProfile.introJs[action])
      } else {
        let _data = {...userProfile};
        _data.introJs[action] = "pending";
        // this is not needed, because unless it is set to "completed", it is assumed to be "pending"
        updateUserProfile(_data.introJs[action], `/introJs/${action}`);
        setIconState("pending");
      }
    }
  }, [userProfile])

  return <div
    className={classes.root + " introJsLauncher" + (iconState === "completed" ? "" : " blob " + classes.pending)}
    onClick={ () => {
      // set intro step to completed...
      setIconState("completed");
      startIntroJs(action, user);
    }}
    style={ isLeft ? {left: 10, ...style} : {right: 10, ...style}}>
    <Help className={classes.icon}/>
  </div>
}
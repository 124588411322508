/*!

=========================================================
* Material ProposalBuilder PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import "animate.css";
import {AuthProvider} from "./firebase/context";
import {CookiesProvider} from "react-cookie";
import DeleteTemplates from "./views/ProposalBuilder/TemplateManager/DeleteTemplates";

ReactDOM.render(
  <CookiesProvider>
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Redirect from="/" to="/admin/home" />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

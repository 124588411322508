import HashLoader from "react-spinners/HashLoader";
import {withStyles} from "@material-ui/core/styles";
import {Box, LinearProgress} from "@material-ui/core";
import React from "react";

export const LoadingScreenStep1 = () => {
  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    zIndex: 9999,
    backgroundColor: "rgba(35,89,153,.95)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const textStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    opacity: .5,
    fontWeight: 500,
    fontSize: "1.125rem",
    textTransform: "uppercase"
  };
  return <div style={containerStyle}>
    <HashLoader color="#fff" size={150} />
    <div style={textStyle}>
      <p style={{marginTop: 200}}>Bitte habe einen Moment Geduld, während wir alles für Dich vorbereiten...</p>
    </div>
    <ProgressBar/>
  </div>
}
export default LoadingScreenStep1

const CustomProgress = withStyles((theme) => ({
  root: {
    height: 6,
  },
  colorPrimary: {
    backgroundColor: "rgba(255,255,255,.5)",
  },
  bar: {
    backgroundColor: '#fff',
  },
}))(LinearProgress);
function ProgressBar() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 350);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '50%' }} style={{marginTop: 100}}>
      <CustomProgress variant="determinate" value={progress}/>
    </Box>
  );
}
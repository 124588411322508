import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useContext, useEffect, useState} from "react";
import {DocumentBuilderContext} from "../context";
import {makeStyles} from "@material-ui/core/styles";
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import {Accordion, AccordionDetails, FormControlLabel, IconButton, Tooltip} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {AddBox, ExpandMore, Check} from "@material-ui/icons";
import Button from "../../../../components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {AppScriptSecret, OpCoOPTIONS} from "../../config";
import {grayColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {firebaseDB} from "../../../../firebase/config";
import {onValue, ref as firebase_ref} from "firebase/database";
import AutoCompleteAsync from "../../../../components/AutoComplete/AutoCompleteAsync";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DISTRICTS, RANDSTAD_VBP_REGIONS} from "../DocumentBuilder/marketAnalyticsConfig";


const useSelectStyles = makeStyles(customSelectStyles);

export function OpCoSelect() {
    const classes = useSelectStyles();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    const [opco, setOpco] = useState(documentData.opco ? documentData.opco : ""); // state required for Select-Component
    return <div id="opcoSelect">
        <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="opco-select" className={classes.selectLabel}>
                OpCo auswählen
            </InputLabel>

            <Select
                MenuProps={{className: classes.selectMenu}}
                classes={{select: classes.select}}
                value={opco}
                onChange={e => {
                    setOpco(e.target.value);
                    updateDocument({...documentData, opco: e.target.value});
                }}
                inputProps={{name: "opcoSelect", id: "opco-select"}}
            >
                <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                    OpCo auswählen
                </MenuItem>
                {/* DIFFERENT OPTIONS FOR TENDER TEAM MEMBERS */}
                {!documentData.isTenderTeamMember && OpCoOPTIONS.map(opco => <MenuItem key={opco.value} classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }} value={opco.value}>
                    <span
                        style={{color: !opco.isSupported ? grayColor[3] : "inherit"}}>{opco.label} {!opco.isSupported && opco.requestable && "auf Anfrage"} {!opco.isSupported && !opco.requestable && "demnächst verfügbar"}</span>
                </MenuItem>)}

                {documentData.isTenderTeamMember && _.filter(OpCoOPTIONS, el => {
                    return el.isSupported || el.tenderTeamOnly
                }).map(opco => <MenuItem key={opco.value} classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }} value={opco.value}>
                    <span
                        style={{color: opco.requestable === false ? grayColor[3] : "inherit"}}>{opco.label} {opco.requestable === false && "demnächst verfügbar"}</span>
                </MenuItem>)}
            </Select>
        </FormControl>
    </div>
}


export function DealTeam() {
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    return <div id="dealTeamBuilder">
        {documentData.dealTeam.map((email, key) => {
                let url = `https://script.google.com/a/macros/gulp.de/s/AKfycbyzDCKPRuucCktqaE4HQNppI32SbpXKb64m9hOcQsqQiO_EoV7eMq6-bLvazW8whght/exec?authToken=${AppScriptSecret}`;
                return <AutoCompleteAsync
                    key={key}
                    autoComplete={true}
                    url={url}
                    name="Kollegen suchen"
                    labelKey={el => el.firstName + " " + el.lastName}
                    optionRenderer={el => {
                        const org = el.organizations && el.organizations.find(el => el.primary);
                        return <div>
                            <p style={{marginBottom: 0}}>{el.firstName} {el.lastName}</p>
                            {org && <small style={{color: grayColor[0]}}>
                                <strong>{org.title}</strong><br/>
                                {org.name}
                            </small>}
                        </div>
                    }}
                />
                {/*<CustomInput
        key={key}
        labelText={`Deal-Team Mitglied ${key + 1}`}
        success={emailRex.test(documentData.dealTeam[key])}
        error={!emailRex.test(documentData.dealTeam[key])}
        id={`dealTeamEmail_${key+1}`}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: "email",
          onChange: e => {
            let _dealTeam = documentData.dealTeam;
            _dealTeam[key] = e.target.value;
            updateDocument({...documentData, dealTeam: _dealTeam})
          },
          value: documentData.dealTeam[key],
          endAdornment: (<IconButton
            aria-label="toggle password visibility"
            onClick={ () => {
              let _dealTeam = documentData.dealTeam;
              _dealTeam.splice(key, 1);
              updateDocument({...documentData, dealTeam: _dealTeam})
            }}
          >
            <Delete/>
          </IconButton>)
        }}
      />*/
                }
            }
        )}
        {documentData.dealTeam.length < 8 && <Button
            color="primary"
            size="sm"
            onClick={() => {
                let _dealTeam = documentData.dealTeam;
                _dealTeam.push("");
                updateDocument({...documentData, dealTeam: _dealTeam})
            }
            }
        >
            <AddBox/> weiteres Deal-Team Mitglied
        </Button>}
    </div>
}


// HELPER FUNCTION FOR PRESET MODULES
const _getTopics = (topics, opco) => {
    let results = _.filter(topics, topic => {
        let a = _.lowerCase(topic.description).replace(/muhlenhoff/g, "muehlenhoff").replace(/mühlenhoff/g, "muehlenhoff");
        let b = _.lowerCase("#opco__" + opco).replace(/muhlenhof/g, "muehlenhoff").replace(/mühlenhoff/g, "muehlenhoff");
        if (b.indexOf("opco muehlenhoff") !== -1) {
            b = "opco muehlenhoff"
        }
        if (b === "opco randstad gruppe") {
            return true
        }
        return a.indexOf(b) !== -1
    })
    return _.sortBy(results, (result) => {
        return result.name.toLowerCase()
    })
}

const useCheckBoxStyles = makeStyles(checkboxStyles);


export function PresetModules() {
    const {topics, updateTopics, documentData, updateDocument, templateLibrary} = useContext(DocumentBuilderContext);
    useEffect(() => {
        const db = firebaseDB;
        let endpoint = 'templates/topics';
        let topicsRef;
        switch (documentData.docType) {
            case "tender":
                topicsRef = firebase_ref(db, endpoint);
                onValue(topicsRef, (snapshot) => {
                    let data = snapshot.val();
                    data = Object.keys(data).map(el => {
                        return {
                            id: data[el].id,
                            name: data[el].name,
                            description: data[el].description
                        }
                    })
                    updateTopics(data);
                });
                break;
            case "salesCollateral":
                endpoint = 'salesCollateralTemplates/presentations';
                topicsRef = firebase_ref(db, endpoint);
                onValue(topicsRef, (snapshot) => {
                    let data = snapshot.val();
                    data = Object.keys(data).map(el => {
                        return {
                            id: data[el].id,
                            name: data[el].name,
                            description: data[el].description,
                            topics: data[el].topics,
                        }
                    })
                    updateTopics(data);
                });
                break;
            default:
                console.log("error: no document type provided", documentData.docType)
        }
    }, [])

    return <div style={{overflowX: "scroll", height: 420}} id="presetModulePicker">
        {documentData.docType === "tender" && <TenderModules/>}
        {documentData.docType === "salesCollateral" && <SalesCollateralModules/>}
        {/* FOR NOW MARKET ANALYTICS DOES NOT REQUIRE THIS STEP
      {documentData.docType === "marketAnalytics" && <MarketAnalyticsModules />}
    */}

    </div>
}


function TenderModules() {
    const {topics, updateTopics, documentData, updateDocument, templateLibrary} = useContext(DocumentBuilderContext);
    const classes = useCheckBoxStyles();

    useEffect(() => {
        const db = firebaseDB;
        let endpoint = 'templates/topics';
        let topicsRef = firebase_ref(db, endpoint);
        onValue(topicsRef, (snapshot) => {
            let data = snapshot.val();
            data = Object.keys(data).map(el => {
                return {
                    id: data[el].id,
                    name: data[el].name,
                    description: data[el].description
                }
            })
            updateTopics(data);
        })
    }, [])

    const handleClick = (e, module) => {
        console.log(templateLibrary);
        if (e.target.checked) {
            debugger;
            let moduleTemplates = _.filter(templateLibrary, template => {
                return template.parents && template.parents.map(el => el.id).includes(module.id)
            })
            moduleTemplates = _.sortBy(moduleTemplates, "name");
            let newData = {...documentData};
            let _templates = newData.templates;
            moduleTemplates.forEach(item => {
                if (!_templates.map(el => el.id).includes(item.id)) {
                    _templates.push(item)
                }
            })
            newData.templates = _templates;
            updateDocument(newData);
        }
    }

    return <div style={{overflowX: "scroll", height: 420}} id="presetModulePicker">
        {_getTopics(topics, documentData.opco).map((module, key) => <div key={key}>
            <Tooltip title={module.description} placement="top-start">
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={key}
                            onClick={e => handleClick(e, module)}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{label: classes.labelDark}}
                    label={module.name.replace("Betalab", "").trim()}
                />
            </Tooltip>
        </div>)}
    </div>
}


function SalesCollateralModules() {
    const {topics, updateTopics, documentData, updateDocument, templateLibrary} = useContext(DocumentBuilderContext);
    const classes = useCheckBoxStyles();

    useEffect(() => {
        const db = firebaseDB;
        let endpoint = 'salesCollateralTemplates/presentations';
        let topicsRef = firebase_ref(db, endpoint);
        onValue(topicsRef, (snapshot) => {
            let data = snapshot.val();
            data = Object.keys(data).map(el => {
                return {
                    id: data[el].id,
                    name: data[el].name,
                    description: data[el].description,
                    topics: data[el].topics,
                }
            })
            updateTopics(data);
        })
    }, [])

    const handleClick = (e, module) => {
        if (e.target.checked) {
            // add mandatory templates
            let mandatoryTemplates = _.filter(templateLibrary, template => {
                return template.parents.includes(module.parent);
            });
            // add selected templates
            let moduleTemplates = _.filter(templateLibrary, template => template.parents.includes(module.id));
            moduleTemplates = moduleTemplates.concat(mandatoryTemplates);
            let newData = {...documentData};
            let _templates = newData.templates;
            moduleTemplates.forEach(item => {
                if (!_templates.map(el => el.id).includes(item.id)) {
                    _templates.push(item)
                }
            })
            newData.templates = _.sortBy(_templates, 'name');
            updateDocument(newData);
        } else {
            let newData = {...documentData};
            newData.templates = newData.templates.filter(template => !template.parents.includes(module.id));
            newData.templates = _.sortBy(newData.templates, 'name');
            if (getMandatoryModulesLength(module.parent) === newData.templates.length) {
                newData.templates = []
            }
            updateDocument(newData);
        }
    }

    const _topics = _getTopics(topics, documentData.opco);

    const getTopicLength = (topicId) => {
        return templateLibrary.filter(template => template.parents.indexOf(topicId) !== -1).length
    }

    const getMandatoryModulesLength = (moduleId) => {
        return templateLibrary.filter(template => template.parents.indexOf(moduleId) !== -1).length
    }
    return <div>
        {_topics?.map((module, key) => <Accordion key={key}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={module.name + "-content"}
                id={module.name + "-header"}
            >
                <strong>
                    {module.name.replace("Betalab", "").trim()}
                </strong>
                <small
                    style={{marginLeft: 10, opacity: .5}}>{getMandatoryModulesLength(module.id)} Pflichtfolien</small>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 20}}>
                    {module?.topics?.map((topic, key) => <Tooltip key={key} title={topic.description}
                                                                placement="top-start">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={key}
                                    onClick={e => handleClick(e, topic)}
                                    checkedIcon={<Check className={classes.checkedIcon}/>}
                                    icon={<Check className={classes.uncheckedIcon}/>}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                            }
                            classes={{label: classes.labelDark}}
                            label={<span>
                {topic.name.replace("Betalab", "").trim()} <small
                                style={{opacity: .5}}>+{getTopicLength(topic.id)} Folien</small>
              </span>}
                        />
                    </Tooltip>)}
                </div>
            </AccordionDetails>
        </Accordion>)}
    </div>
}


function MarketAnalyticsModules() {
    const {topics, updateTopics, documentData, updateDocument, templateLibrary} = useContext(DocumentBuilderContext);
    const classes = useCheckBoxStyles();

    useEffect(() => {
        const db = firebaseDB;
        let endpoint = 'marketAnalytics/slides';
        let slidesRef = firebase_ref(db, endpoint);
        onValue(slidesRef, (snapshot) => {
            let data = snapshot.val();
            data = Object.keys(data).map(el => {
                return {
                    id: data[el].id,
                    name: data[el].name,
                    description: data[el].description
                }
            })
            updateTopics(data);
        })
    }, [])

    const handleClick = (e, module) => {
        if (e.target.checked) {
            let moduleTemplates = _.filter(templateLibrary, template => {
                return template.parents.map(el => el.id).includes(module.id)
            })
            moduleTemplates = _.sortBy(moduleTemplates, "name");
            let newData = {...documentData};
            let _templates = newData.templates;
            moduleTemplates.forEach(item => {
                if (!_templates.map(el => el.id).includes(item.id)) {
                    _templates.push(item)
                }
            })
            newData.templates = _templates;
            updateDocument(newData);
        }
    }

    return <div style={{overflowX: "scroll", height: 420}} id="presetModulePicker">
        {_getTopics(topics, documentData.opco).map((module, key) => <div key={key}>
            <Tooltip title={module.description} placement="top-start">
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={key}
                            onClick={e => handleClick(e, module)}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{label: classes.labelDark}}
                    label={module.name.replace("Betalab", "").trim()}
                />
            </Tooltip>
        </div>)}
    </div>
}


export function MarketAnalyticsDistrictSelect() {
    // this is only used for market analytics reports and uses hard coded district codes for creating the screenshots later on
    const classes = useSelectStyles();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);

    const handleSelect = (e, val) => {
        let newData = {...documentData};
        newData.district = val;
        updateDocument(newData);
    }

    return <FormControl fullWidth className={classes.selectFormControl}>
        <Autocomplete
            id="district-select"
            options={DISTRICTS}
            groupBy={(option) => option.state}
            getOptionLabel={(option) => option.district}
            onChange={handleSelect}
            //style={{width: 300}}
            renderInput={(params) => <TextField {...params} label="Kreis auswählen" variant="outlined"/>}
        />
    </FormControl>
}


export function MarketAnalyticsVBPRegionSelect() {
    // this is only used for market analytics reports and uses hard coded district codes for creating the screenshots later on
    // these are the regions that are required for Randstad VBP aka signal.randstadservices.com
    const classes = useSelectStyles();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);

    const handleSelect = (e, val) => {
        let newData = {...documentData};
        newData.vbp_district = val;
        updateDocument(newData);
    }

    return <FormControl fullWidth className={classes.selectFormControl}>
        <Autocomplete
            id="district-select"
            options={RANDSTAD_VBP_REGIONS}
            getOptionLabel={option => option}
            onChange={handleSelect}
            //style={{width: 300}}
            renderInput={(params) => <TextField {...params} label="VBP Kreis auswählen" variant="outlined"/>}
        />
    </FormControl>
}

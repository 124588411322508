import React, {useContext, useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {firebaseDB} from "../../../../../firebase/config";
import {onValue, ref as firebase_ref} from "firebase/database";
import LoadingScreenStep1 from "../loadingScreen";
import {DocumentBuilderContext} from "../../context";
import {Box, Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import _ from "lodash";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import axios from "axios";
import qs from "qs";


export default function MarketAnalyticsStep1(){
    const [showProfessions, setShowProfessions] = useState(false);

    return <div style={{position: "relative", height: "100%"}}>
        <DistrictSelector onClear={bool => setShowProfessions(bool)}/>
        {showProfessions && <Box my={6}>
            <Divider/>
        </Box>}
        {showProfessions && <ProfessionSelector/>}
    </div>
}

function DistrictSelector({onClear}){
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);

    useEffect(() => {
        if (documentData.mainDistrict){
            if (documentData && documentData.district.map(el => el.id).indexOf(documentData.mainDistrict.id) < 0){
                console.log("dropping main value")
                let newData = {...documentData};
                newData.mainDistrict = null;
                updateDocument(newData);
            }
        }
    }, [documentData.district])

    useEffect(() => {
        const districtsRef = firebase_ref(firebaseDB, '/marketAnalyticsTemplates/mappingTables/locations');
        onValue(districtsRef, (snapshot) => {
            let data = snapshot.val();
            let _data = {};
            data.map( el => {
                let obj = {...el};
                obj.plz = [];
                _data[el.kgs5] = obj;
            })
            data.map(el => {
                _data[el.kgs5].plz.push(el.plz)
            });
            setOptions(Object.keys(_data).map(key => _data[key]));
            setLoading(false);
        })
    }, [])

    return <div>
        {loading && <LoadingScreenStep1/>}
        <div style={{marginTop: "1rem"}}>
            <Autocomplete
                id="district-select"
                value={documentData.district}
                options={options}
                multiple
                getOptionLabel={option => option.kgs5 + " (" + option.kreisname + ")"}
                renderOption={option => {
                    return <div>
                        <p style={{marginBottom: 0}}> KGS5: {option.kgs5} | {option.kreisname}</p>
                        <small>PLZ: {option.plz.join(", ")}</small>
                    </div>
                }}
                onChange={(e, val, reason) => {
                    let newData = {...documentData};
                    if(val.length === 1){
                        newData.mainDistrict = val[0]
                    }
                    newData.district = val;
                    updateDocument(newData);
                    if (val.length > 0){
                        onClear(true)
                    } else {
                        onClear(false)
                    }
                }}
                //style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Kreis auswählen" variant="outlined"/>}
            />
        </div>
        <div style={{marginTop: 10}}>
            <h6 style={{opacity: .5}}>HAUPT-KREIS WÄHLEN</h6>
            {documentData.district.map(el => <p
                key={el.kgs5}
                onClick={() => {
                    console.log(el, documentData.mainDistrict)
                    let newData = {...documentData};
                    newData.mainDistrict = el;
                    updateDocument(newData);
                }}
                style={{fontWeight: el.kgs5 === documentData.mainDistrict.kgs5 ? "bold" : "normal", cursor: "pointer"}}
            >
                {el.kgs5} ({el.kreisname})
            </p>)}
        </div>
    </div>
}

function ProfessionSelector(){
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [baBerufe, setBaBerufe] = useState([]);
    const [jobfeedBerufe, setJobfeedBerufe] = useState([]);
    const [entgeltAtlasBerufe, setEntgeltAtlasBerufe] = useState([]);
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);

    useEffect(() => {
        const db = firebaseDB;
        const iscoRef = firebase_ref(db, '/marketAnalyticsTemplates/mappingTables/isco');
        onValue(iscoRef, (snapshot) => {
            let data = snapshot.val();
            data = Object.keys(data).map( el => {
                return {id: el, name: data[el]}
            })
            setOptions(data);
        })

        const baBerufeRef = firebase_ref(db, '/marketAnalyticsTemplates/mappingTables/baBerufe');
        onValue(baBerufeRef, (snapshot) => {
            let data = snapshot.val();
            setBaBerufe(data);
        })

        const jobfeedBerufeRef = firebase_ref(db, '/marketAnalyticsTemplates/mappingTables/jobfeedBerufe');
        onValue(jobfeedBerufeRef, (snapshot) => {
            let data = snapshot.val();
            setJobfeedBerufe(data);
        })
        setLoading(false);
        updateEntgeltatlasBerufe()
    }, [])

    const updateEntgeltatlasBerufe = () => {
        const getProfessions = (token, url, _professions) => {
            axios({
                url,
                headers: {
                    OAuthAccessToken: token
                }
            }).then(r => {
                const {berufe} = r.data._embedded;
                _professions = [..._professions, ...berufe]
                if(r.data._links.next){
                    getProfessions(token, r.data._links.next.href, _professions)
                } else {
                    setLoading(false);
                    _professions = _professions.map(el => {
                        return {
                            id: el.id,
                            kldb2010: el.kldb2010.replace("B ", ""),
                            kurzBezeichnungNeutral: el.kurzBezeichnungNeutral,
                            bezeichnungNeutral: el.bezeichnungNeutral,
                            codenr: el.codenr
                        }
                    })
                    setEntgeltAtlasBerufe(_professions)
                }
            }).catch(err => console.log("error getting entgeltatlas berufe", err))
        }

        setLoading(true);
        const clientId = 'c4f0d292-9d0f-4763-87dd-d3f9e78fb006';
        const clientSecret = '566c4dd6-942f-4cda-aad6-8d611c577107';
        const postData = {
            grant_type: 'client_credentials',
            client_id: clientId,
            client_secret: clientSecret
        };

        axios({
            url: 'https://rest.arbeitsagentur.de/oauth/gettoken_cc',
            method: 'post',
            data: qs.stringify(postData),
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        }).then((response) => {
            const token = response.data.access_token;
            const url = "https://rest.arbeitsagentur.de/infosysbub/dkz-rest/pc/v1/berufe?zustand.id=E&bkgr.typ.id=t&bkgr.id=1910,2910,2920,2930,2940,3910,3920,4910,7910&size=200";
            getProfessions(token, url, []);
        }).catch(err => console.log("Error authenticating entgeltatlas", err))
    }

    const handleOnChange = (e, val, reason) => {
        let newData = {...documentData};
        if (reason === "select-option"){
            let _val = val[val.length - 1];
            _val.qualiBALang = "";
            _val.qualiKurz = "";
            _val.jobfeedNames = jobfeedBerufe.filter(el => el.isco === _val.id).map(el => el.label);
            newData.professions = val;
        }
        if (reason === "remove-option"){
            newData.professions = val;
        }
        updateDocument(newData);
    }

    return <div>
        {loading && <LoadingScreenStep1/>}
        <div style={{marginTop: "1rem"}}>
            <Autocomplete
                id="district-select"
                value={documentData.professions}
                options={options}
                multiple
                getOptionLabel={option => option.id}
                renderOption={option => {
                    return <div>
                        <p style={{marginBottom: 0}}>{option.id}</p>
                        <small>{option.name}</small>
                    </div>
                }}
                onChange={handleOnChange}
                renderInput={(params) => <TextField {...params} label="ISCO auswählen" variant="outlined"/>}
            />

            <div style={{marginTop: 10}}>
                <h6 style={{opacity: .5}}>Berufe spezifizieren</h6>
                {documentData.professions && documentData.professions.map(isco => {
                    const kldb4 = _.uniqBy(baBerufe.filter(el => el.isco === isco.id), "kldb4")
                    const _entgeltAtlasBerufe = entgeltAtlasBerufe.filter(el => kldb4.map(el => el.kldb4).includes(el.kldb2010.substring(0,4)))
                    return <Profession
                        key={isco.id}
                        isco={isco.id}
                        label={isco.name}
                        kldb4={kldb4}
                        baBerufe={baBerufe.filter(el => el.isco === isco.id)}
                        entgeltAtlasBerufe={_entgeltAtlasBerufe}
                    />
                })}
            </div>
        </div>
    </div>
}

function Profession({isco, label, kldb4, baBerufe, entgeltAtlasBerufe}){
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    let index = documentData.professions.findIndex(el => el.id === isco);
    const {qualiBALang, qualiKurz} = documentData.professions[index];

    useEffect(() => {
        let newData = {...documentData};
        newData.professions[index].qualiBALang = entgeltAtlasBerufe[0].kurzBezeichnungNeutral;
        updateDocument(newData)
    }, [])

    return <Paper elevation={1}>
        <Box p={1} mb={2}>
            <p style={{marginBottom: 0, fontWeight: "bold"}}>{isco} | {label}</p>
            <FormControl style={{width: "100%"}}>
                <InputLabel id={`quali_ba_lang-${isco}-select-label`}>Quali BA lang</InputLabel>
                <Select
                    labelId={`quali_ba_lang-${isco}-select-label`}
                    id={`quali_ba_lang-${isco}-select`}
                    value={qualiBALang}
                    style={{width: "100%"}}
                    onChange={e => {
                        let newData = {...documentData};
                        newData.professions[index].qualiBALang = e.target.value;
                        updateDocument(newData)
                    }}
                >
                    {entgeltAtlasBerufe.map(el => <MenuItem
                        key={el.id}
                        value={el.kurzBezeichnungNeutral}>
                        {el.kurzBezeichnungNeutral}
                    </MenuItem>)}
                </Select>
            </FormControl>

            <CustomInput
                labelText="Quali Kurz?"
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    value: qualiKurz,
                    onChange: e => {
                        let newData = {...documentData};
                        newData.professions[index].qualiKurz = e.target.value;
                        updateDocument(newData);
                    }
                }}
            />
        </Box>
    </Paper>
}
import {DEAL_TEAM_ROLES} from "../views/ProposalBuilder/config";

export const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const tagIsPositionName = tag => {
  let positions = DEAL_TEAM_ROLES.map( role => role.replace(/ /g, "_").toUpperCase());
  return positions.some( position => {
    let normalizedTag = tag.tag.replace("{{ ", "{{").replace(" }}", "}}");
    let isProperNameTag = normalizedTag.startsWith("{{" + position) && normalizedTag.endsWith("NAME}}"); // normally all name tags should end with the suffix "name"
    let isImproperNameTag = normalizedTag === `{{${position}}`; // due to user faults, this might not be the case and needs to be handled as well
    return isProperNameTag || isImproperNameTag
  })
};

export const tagIsPositionTitle = tag => {
  let normalizedTag = tag.tag.replace("{{ ", "{{").replace(" }}", "}}");
  return normalizedTag.endsWith("_TITEL}}")
};

export const tagIsHeSheGenderfication = tag => {
  let normalizedTag = tag.tag.replace("{{ ", "{{").replace(" }}", "}}");
  normalizedTag = normalizedTag.toLowerCase();
  return normalizedTag.startsWith("{{#he_she") || normalizedTag.startsWith("{{#she_he")
};

export const tagIsMarketAnalyticsDistrict = tag => {
  let normalizedTag = tag.tag.replace("{{ ", "{{").replace(" }}", "}}");
  normalizedTag = normalizedTag.toLowerCase();
  return normalizedTag.startsWith("{{standort")
};

export const tagIsAddressBlock = tag => {
  let normalizedTag = tag.tag.replace("{{ ", "{{").replace(" }}", "}}");
  return normalizedTag.endsWith("_ADDRESS_BLOCK}}")
};

export const normalizeTextTag = tag => {
  return tag.replace("{{ ", "{{").replace(" }}", "}}").toLowerCase()
};

export const getAllDealTeamRoleTags = (templates) => {
  let tags = [];
  templates.forEach( template => {
    template.tags.textTags && template.tags.textTags.forEach( textTag => {
      if (tagIsPositionName(textTag)){
        tags.push(textTag)
      }
    })
  })
  return _.uniqBy(tags, el => normalizeTextTag(el.tag));
}
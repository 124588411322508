import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import TemplateList from "./templateList";
import Fuse from 'fuse.js';
import _ from "lodash";
import LoadingScreenStep2 from "./loadingScreen";
import {firebaseDB} from "../../../../firebase/config";
import {onValue, ref as firebase_ref} from "firebase/database";
import {DocumentBuilderContext} from "../context";
import {IntroLauncher} from "../../intro_js";


export function TemplateLookUp(){
  const { documentData, templateLibrary, updateTemplateLibrary } = useContext(DocumentBuilderContext);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState(""); // the text by which the use searches for a specific company name
  useEffect(() => {
    loadTemplates();
    setLoading(false);
  }, [])

  function loadTemplates(){
    const db = firebaseDB;
    let endpoint;
    switch(documentData.docType){
      case "tender":
        endpoint = "templates/files";
        break;
      case "salesCollateral":
        endpoint = "salesCollateralTemplates/files";
        break;
      case "marketAnalytics":
        endpoint = "marketAnalyticsTemplates/files";
        break;
      default:
        console.log("error: no document type provided", documentData.docType)

    }
    const templatesRef = firebase_ref(db, endpoint);
    onValue(templatesRef, (snapshot) => {
      let data = snapshot.val();
      data = Object.keys(data).map( el => {
        return {
          id: data[el].id,
          name: data[el].name,
          createdTime: data[el].createdTime,
          description: data[el].description,
          opcos: data[el].opcos,
          topics: data[el].topics,
          parents: data[el].parents,
          isDefault: data[el].isDefault,
          tags: data[el].tags ? data[el].tags : {},
        }
      })
      updateTemplateLibrary(data);
      setLoading(false);
    })
  }

  // uses fuse.js to run a fuzzy search on all clients
  // this is necessary, because Google Drive API does not have a fuzzy search available
  const filterTemplates = () => {
    if (documentData.docType === "marketAnalytics"){
      return _.sortBy(templateLibrary, [el => el.name.trim()])
    }
    if (!documentData.opco){
      return []
    }
    let _templateLibrary = _.filter(templateLibrary, template => {
      if (documentData.opco === 'randstad_gruppe'){
        return templateLibrary
      }
      return template.opcos && template.opcos.some( opco => opco.toLowerCase().replace(/muhlenhoff/g, "muehlenhoff").replace(/mühlenhoff/g, "muehlenhoff").includes(documentData.opco.toLowerCase().replace("_by_randstad", "")))
    });
    let results = _.sortBy(_templateLibrary, [el => el.name.trim()]);
    if (searchText.length > 2){
      const fuseOptions = {
        keys: [
          'name',
          'description',
          'lastModifyingUser.displayName',
          'topics',
          'opcos'
        ],
        threshold: .3,
        ignoreLocation: true,
      };
      const fuse = new Fuse(results, fuseOptions);
      results = fuse.search(searchText).map( el => el.item);
      results.push({id: -1, name: `Neu erstellen: "${searchText}"`});
    }
    return results.sort();
  }

  return <div id="templateLookup" style={{position: "relative"}}>
    <IntroLauncher action="templateList"/>
    {loading && <LoadingScreenStep2/>}
    <SearchInput
      val={searchText}
      onChange={ val => setSearchText(val)}
    />
    <TemplateList templates={filterTemplates()}/>
  </div>
}


function SearchInput({onChange, value}){
  return <div>
    <CustomInput
      labelText={<span>
        Vorlage suchen...
      </span>}
      id="templateSearchInput"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        onChange: e => onChange(e.target.value),
        value: value,
      }}
    />
  </div>
}
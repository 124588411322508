import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress, LinearProgress} from "@material-ui/core";
import { ref as firebase_ref,  onValue} from "firebase/database";
import {firebaseDB} from "../../../../firebase/config";


const useCountDownTimerStyles = makeStyles({
    countdownContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    countdownText: {

    },
    progressCircleRoot: {
        position: "absolute",
        zIndex: 999,
    },
    progressCircle: {
        color: "#2175d9",
    },
    progressBackground: {
        color: "#e8ebed"
    },
    linearProgressRoot: {
        width: 300,
        marginBottom: ".75rem"
    },
    linearProgressBar: {
        backgroundColor: "#2175d9"
    },
    linearProgressBar2: {
        backgroundColor: "#e8ebed"
    }
})
export function CountDownTimer({templates, startingTimestamp, progressTrackerId}){
    const numDocs = templates.length;
    const [progress, setProgress] = useState(1);
    const [currentStep, setCurrentStep] = useState("");
    const classes = useCountDownTimerStyles();
    const progressLabel = Math.round((progress / (numDocs + 10)) * 100);

    useEffect(()=>{
        getCurrentProgress()
    }, [])

    const getCurrentProgress = () => {
        const db = firebaseDB;
        const progressTrackerRef = firebase_ref(db, 'progressTrackers/' + progressTrackerId);
        onValue(progressTrackerRef, (snapshot) => {
            const data = snapshot.val();
            setCurrentStep(data);
            setProgress(prevProgress => prevProgress + 1);
        });
    }

    /*const calculateDuration = () => {
        let duration = 0;
        // time for loading templates (1s per template)
        duration = duration + (numDocs * 1);
        // time for import styleguide
        duration = duration + 15;
        // time for import all pages
        duration = duration + (numDocs * 15);
        // time lost while making batch requests
        duration = duration + (Math.ceil(numDocs/10) * 5);
        // add buffer
        duration = duration + 10;
        // return duration in seconds
        return duration
    }

    useEffect(()=>{
        const _timer = setInterval(()=>{
            setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + .1))
        }, calculateDuration());
        return () => {
            clearInterval(_timer)
        }
    }, [])

    const Completionist = () => <h1 style={{color: "#999999"}}>Aktuell ist viel los, daher dauert es leider noch ein wenig länger...!</h1>;

    // Renderer callback with condition
    const TimerRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <h1 style={{color: "#999999"}}>{zeroPad(minutes)}:{zeroPad(seconds)}</h1>;
        }
    };*/

    return <div className={classes.countdownContainer}>
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={(progress / (numDocs + 10)) * 100}
                size={140}
                thickness={1.5}
                classes={{
                    root: classes.progressCircleRoot,
                    circle: classes.progressCircle
                }}
            />
            <CircularProgress
                variant="determinate"
                value={100}
                size={140}
                thickness={1.5}
                classes={{
                    circle: classes.progressBackground
                }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <h1>{progressLabel > 100 ? 100 : progressLabel}%</h1>
            </Box>
        </Box>
        <h4 style={{opacity: .75}}>{currentStep}</h4>
        <LinearProgress classes={{
            bar1Indeterminate: classes.linearProgressBar,
            bar2Indeterminate: classes.linearProgressBar,
            colorPrimary: classes.linearProgressBar2,
            root: classes.linearProgressRoot
        }}
        />
        {/*<CountdownSteps templates={templates}/>*/}
    </div>
}


function CountdownSteps({templates}){
    const steps = [
        {name: "Vorlagen werden kopiert", duration: 10},
        {name: "Inhalte werden überprüft und angepasst", duration: 15},
        {name: ""}
    ]
    const [currentTemplate, setCurrentTemplate] = useState(steps[0]);
    useEffect(()=>{
        const _timer = setInterval(()=>{
            setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + .1))
        }, calculateDuration());
        return () => {
            clearInterval(_timer)
        }
    }, [])
    return <div>

    </div>
}
import React, {useContext, useEffect, useState} from 'react'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { makeStyles } from "@material-ui/core/styles";
import {dangerColor, grayColor, successColor, whiteColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {DocumentBuilderContext} from "../context";
import {Apps, Check, Delete, Edit, Remove} from "@material-ui/icons";
import {firebaseDB} from "../../../../firebase/config";
import {onValue, ref as firebase_ref} from "firebase/database";
import Button from "../../../../components/CustomButtons/Button";
import {OpCoOPTIONS} from "../../config";
import {IntroLauncher} from "../../intro_js";
import {
  tagIsAddressBlock,
  tagIsHeSheGenderfication, tagIsMarketAnalyticsDistrict,
  tagIsPositionName,
  tagIsPositionTitle
} from "../../../../components/helpers";

const styles = {
  container: {
    display: "flex",
    touchAction: "none",
    width: "100%",
    overflow: "scroll",
    "-webkit-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "-moz-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
  },
  dropzone: {
    flex: "1",
    height: 1,
    borderRadius: 4,
  },

  gridItem: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: ".5em",
  },

  gridItemContent: {
    background: "rgba(200,200,200,.2)",
    border: `1px solid ${grayColor[5]}`,
    borderRadius: 4,
    width: "100%",
    height: "100%",
    padding: "1rem",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, Helvetica, sans-serif",
    cursor: "pointer",
  },

  thumbnail: {
    position: "absolute",
    height: "75%",
    width: "75%",
    zIndex: 1,
    "-webkit-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "-moz-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
  },

  gridItemDescription: {
    background: whiteColor,
    opacity: .75,
    borderTop: `1px solid ${grayColor[5]}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: ".75rem",
    margin: ".5rem",
    position: "absolute",
    height: 80,
    width: "calc(100% - 16px)",
    zIndex: 9,
    bottom: 0,
    left: 0,
    padding: ".25rem",
    display: "flex",
    flexDirection: "column"
  },
  gridItemDescriptionHoverable: {
    background: whiteColor,
    borderTop: `1px solid ${grayColor[5]}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: ".75rem",
    margin: ".5rem",
    position: "absolute",
    height: 80,
    width: "calc(100% - 16px)",
    zIndex: 9,
    bottom: 0,
    left: 0,
    padding: ".25rem",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      height: 200,
      "-moz-transition": "height .25s ease",
      "-webkit-transition": "height .25s ease",
      "-o-transition": "height .25s ease",
      "transition": "height .25s ease",
    }
  },
  gridItem_title: {
    fontWeight: 700
  },
  gridItem_description: {
    overflow: "hidden",
    fontSize: "85%",
    opacity: .75,
    lineHeight: 1.5,
    "-moz-transition": "height .25s ease",
    "-webkit-transition": "height .25s ease",
    "-o-transition": "height .25s ease",
    "transition": "height .25s ease",
  },
  deleteIcon: {
    backgroundColor: "rgba(0,0,0,.25)",
    color: "#fff",
    borderRadius: 10,
    height: 20,
    width: 20,
    padding: 3,
    position: "absolute",
    zIndex: 50,
    top: 12,
    left: 12,
    "-webkit-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "-moz-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "&:hover": {
      "-webkit-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "-moz-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
    }
  },
  editIcon: {
    backgroundColor: "rgba(0,0,0,.25)",
    color: "#fff",
    borderRadius: 10,
    height: 20,
    width: 20,
    padding: 3,
    position: "absolute",
    zIndex: 50,
    top: 12,
    right: 12,
    "-webkit-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "-moz-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "&:hover": {
      "-webkit-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "-moz-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
    }
  },
  successIcon: {
    backgroundColor: successColor[0],
    color: "#fff",
    borderRadius: 10,
    height: 20,
    width: 20,
    padding: 3,
    position: "absolute",
    zIndex: 50,
    top: 12,
    right: 12,
    "-webkit-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "-moz-box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "box-shadow": "0px 0px 4px 0px rgba(0,0,0,0.25)",
    "&:hover": {
      "-webkit-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "-moz-box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
      "box-shadow": "0px 0px 8px 0px rgba(0,0,0,0.25)",
    }
  },
  gridItem_tags: {
    position: "absolute",
    zIndex: 10,
    bottom: 0,
    left: 0,
    background: "#fff",
    borderTop: `1px solid ${grayColor[5]}`,
    width: "100%",
    padding: 4,
  },
  tag: {
    background: grayColor[5],
    color: grayColor[2],
    padding: "2px 4px",
    fontSize: "85%",
    marginRight: 4,
  },
  noContentAlert: {
    position: "absolute",
    zIndex: 999,
    height: 532 * .86,
    width: "calc(66% - 22px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    opacity: .5
  }
}

const useStyles = makeStyles(styles);


export default function index ({onCardClick}) {
  const classes = useStyles();
  const [gridSize, setGridSize] = useState(3)
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);
  const items = documentData.templates;

  function onChange(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(items, sourceIndex, targetIndex);
    updateDocument({
      ...documentData,
      templates: nextState,
    })
  }

  const getHeight = () => {
    return Math.ceil(items.length/gridSize) * 250
  }

  return <div style={{position: "relative"}}>
    <IntroLauncher action={documentData.templates.length < 1 ? "buildingAreaEmpty" : "buildingArea"} isLeft={true} style={{marginTop: -30}}/>
    <div id="gridSizeToggler" style={{ float: "right", marginTop: -30, width: 100}}>
      <Button color="primary" simple size="sm" onClick={()=>setGridSize(gridSize === 3 ? 6 : 3)}>
        <Apps/> {gridSize === 3 ? 6 : 3} pro Zeile
      </Button>
    </div>
    <GridContextProvider onChange={onChange}>

      {documentData.templates.length < 1 && <div className={classes.noContentAlert}>
        Du hast noch keine Vorlagen ausgewählt.
        <br/>
        &#8592; Wähle jetzt Vorlagen in der linken Spalte aus.
      </div>}

      <div className={classes.container}>
        <GridDropZone
          className={classes.dropzone}
          style={{height: getHeight()}}
          id='templateDnD'
          boxesPerRow={gridSize}
          rowHeight={gridSize === 3 ? 250 : 125}
        >
          {items.map( (item, key) => (
            <FileCard
              key={key}
              introJsHelper={key}
              classes={classes}
              item={item}
              onCardClick={onCardClick}
              gridSize={gridSize}
            />
          ))}
        </GridDropZone>
      </div>
    </GridContextProvider>
  </div>
}

function FileCard({item, classes, onCardClick, gridSize, introJsHelper}){
  const [promptDelete, setPromptDelete] = useState(false);
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);

  let textTagsComplete = true;
  let selectionTagsComplete = true;
  if (item.tags.textTags && item.tags.textTags
      .filter(tag => (!tagIsPositionName(tag) && !tagIsPositionTitle(tag) && !tagIsAddressBlock(tag)))
      .filter(tag => !tagIsHeSheGenderfication(tag))
      .filter(tag => !tagIsMarketAnalyticsDistrict(tag))
      .some(el => el.value.length < 1)){
    textTagsComplete = false
  }
  if (item.tags.selectionTags && item.tags.selectionTags.some(el => el.value.length < 1)){
    selectionTagsComplete = false;
  }
  let tagsComplete = textTagsComplete && selectionTagsComplete;
  if (item.description.includes("#multipleProfessions")){
    tagsComplete = true;
  }

  useEffect(() => {
    getThumbnail()
  }, [])

  const getThumbnail = () => {
    const db = firebaseDB;
    let endpoint;
    switch(documentData.docType){
      case "tender":
        endpoint = 'templates/thumbnails'
        break;
      case "salesCollateral":
        endpoint = "salesCollateralTemplates/thumbnails";
        break;
      case "marketAnalytics":
        endpoint = "marketAnalyticsTemplates/thumbnails";
        break;
      default:
        console.log("error: no document type provided", documentData.docType);
    }
    const thumbnailRef = firebase_ref(db, `${endpoint}/${item.id}`);
    onValue(thumbnailRef, (snapshot) => {
      let data = snapshot.val();
      let newData = {...documentData};
      let cardIndex = newData.templates.findIndex( el => item.id === el.id);
      newData.templates[cardIndex].thumbnail = data;
      updateDocument(newData);
    })
  }

  const toggleDelete = () => {
    setPromptDelete(true);
    setTimeout(() => {
      setPromptDelete(false)
    }, 2500)
  }

  const removeCard = () => {
    let newData = {...documentData};
    let cardIndex = newData.templates.findIndex( el => item.id === el.id);
    newData.templates.splice(cardIndex, 1);
    updateDocument(newData);
  }

  const getItemName = () => {
    let opcoObj = OpCoOPTIONS.find( el => el.value === documentData.opco);
    return opcoObj
        ? item.name.replace("Angebotsvorlage", "").replace(opcoObj.label, "").trim()
        : item.name
  }
  return <div>
    <GridItem id={"thumbnailNo_" + introJsHelper}>
      <div className={classes.gridItem}>
        <div className={classes.gridItemContent}>
          { !promptDelete
            ? <Remove className={classes.deleteIcon + " thumbnail_deleteIcon"} onClick={toggleDelete}/>
            : <Delete color="action" style={{backgroundColor: dangerColor[0]}} className={classes.deleteIcon} onClick={removeCard}/>
          }
          {tagsComplete
            ? <Check className={classes.successIcon + " thumbnail_editIcon"} onClick={() => onCardClick(item)}/>
            : <Edit className={classes.editIcon + " thumbnail_editIcon"} onClick={() => onCardClick(item)}/>
          }
          <div className={classes.thumbnail + " thumbnail_img"} style={{backgroundImage: `url("data:image/png;base64,${item.thumbnail}")`, backgroundSize: "contain", backgroundRepeat: "no-repeat"}}/>
          <div className={gridSize < 6 ? classes.gridItemDescriptionHoverable : classes.gridItemDescription}>
            <div className={classes.gridItem_title}>
              {getItemName()}
            </div>
            {gridSize < 6 && <div className={classes.gridItem_description}>
              {item.description}
            </div>}
          </div>
        </div>
      </div>
    </GridItem>
  </div>
}

import CustomInput from "../../components/CustomInput/CustomInput";
import React, {useContext, useEffect, useState} from "react";
import { ref as firebase_ref, onValue } from "firebase/database";
import {firebaseDB} from "../../firebase/config";
import Search from "@material-ui/icons/Search";
import {AuthContext} from "../../firebase/context";
import {DocumentBuilderContext} from "./WizardSteps/context";
import _ from "lodash";
import Fuse from "fuse.js";
import {makeStyles} from "@material-ui/core/styles";
import {
  boxShadow,
  dangerColor,
  primaryColor,
} from "../../assets/jss/material-dashboard-pro-react";
import modalStyles from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import {Dialog, DialogActions, DialogContent, Slide} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import {Warning} from "@material-ui/icons";
import LoadingScreenStep1 from "./WizardSteps/Step1/loadingScreen";


const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
    width: "210px !important" ,
  },
  searchInput: {
    paddingTop: "2px",
  },
  tenderDropdown: {
    position: "absolute",
    left: 0,
    top: 26,
    width: 238,
    background: "#fff",
    ...boxShadow,
  },
  tenderDropdownItem: {
    cursor: "pointer",
    padding: 10,
    margin: 2,
    "&:hover": {
      background: primaryColor[0],
      color: "#fff",
      fontWeight: 700,
      borderRadius: 2
    }
  }
});


function TenderSearcher() {
  const classes = useStyles();
  const {user} = useContext(AuthContext);
  const { tenders, updateTenders, documentData, updateDocument, updateDownloadedContent } = useContext(DocumentBuilderContext);
  const [searchText, setSearchText] = useState("");
  const [selectedTender, setSelectedTender] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTenders()
  }, [])

  const loadTenders = () => {
    let userEmail = user.email.replace(/\./g, "__dot__").replace(/@/g, "__at__");
    const db = firebaseDB;
    const tendersRef = firebase_ref(db, `/tenders/index/${userEmail}`);
    onValue(tendersRef, (snapshot) => {
      let data = snapshot.val();
      if (data){
        let _data = Object.keys(data).map( key => {return {id: key, tender: data[key]}})
        updateTenders(_data);
      }
    })
  }

  const filterTenders = () => {
    let results = [];
    if (searchText.length > 2){
      results = _.sortBy(tenders, [el => el.tender.name.trim()]);
      const fuseOptions = {
        keys: ['tender.name'],
        threshold: .6
      };
      const fuse = new Fuse(tenders, fuseOptions);
      results = fuse.search(searchText).map( el => el.item);
    }
    return results.sort();
  }

  const handleSelect = () => {
    // TODO: "LOADING!
    setLoading(true);
    setSearchText("");
    const db = firebaseDB;
    const tenderRef = firebase_ref(db, `/tenders/documents/${selectedTender.id}`);
    onValue(tenderRef, (snapshot) => {
      let data = snapshot.val();
      let newData = {...documentData};
      newData = data;
      updateDocument(newData);
      setSelectedTender(undefined);
      updateDownloadedContent(true);
    },{
      onlyOnce: true
    })
    setLoading(false);
    setSelectedTender(false);
  }

  return <span className={classes.root}>
    {loading && <LoadingScreenStep1/>}
    <CustomInput
      formControlProps={{
        className: classes.top + " " + classes.search,
        style: {width: 210}
      }}
      inputProps={{
        value: searchText,
        placeholder: "Meine Tender durchsuchen...",
        inputProps: {
          "aria-label": "Meine Tender durchsuchen...",
          className: classes.searchInput,
          style: {width: 300}
        },
        onChange: e => setSearchText(e.target.value),
        endAdornment: <Search style={{opacity: .5}}/>
      }}
    />
    <span className={classes.tenderDropdown}>
      {filterTenders().map( (el, key) => <div key={key} className={classes.tenderDropdownItem} onClick={ () => setSelectedTender(el) }>
        {el.tender.name}
      </div>)}
    </span>
    <OpenTenderModal
      isOpen={selectedTender !== undefined}
      onClose={() => setSelectedTender(undefined)}
      tender={selectedTender}
      handleSelect={handleSelect}
    />
  </span>
}
export default TenderSearcher;


const useModalStyles = makeStyles({
  ...modalStyles,
  link: {
    color: primaryColor[0],
    textDecoration: "underline"
  },
  alert: {
    color: dangerColor[0],
    display: "flex",
    alignItems: "flex-end",
  }
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function OpenTenderModal({isOpen, onClose, tender, handleSelect}){
  const classes = useModalStyles();
  return <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal
    }}
    open={isOpen}
    transition={Transition}
    keepMounted
    onClose={onClose}
    aria-labelledby="modal-slide-title"
    aria-describedby="modal-slide-description"
  >
    {tender && <DialogContent
      id="modal-slide-description"
      className={classes.modalBody}
    >
      <h2>Bestehenden Tender öffnen</h2>
      <p>Möchtest Du den Tender <a className={classes.link} href={`https://drive.google.com/drive/folders/${tender.tender.id}`} target={"_blank"}><strong>{tender.tender.name}</strong></a> öffnen?</p>
      <div className={classes.alert}><Warning/> Wenn Du diesen Tender öffnest, gehen alle ungespeicherten Fortschritte verloren.</div>
    </DialogContent>}
    <DialogActions style={{display: "flex", justifyContent: "space-between"}}>
      <Button simple onClick={onClose} color="danger">
        Abbrechen
      </Button>
      <Button onClick={handleSelect} color="primary">
        Tender öffnen
      </Button>
    </DialogActions>
  </Dialog>
}
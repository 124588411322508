import React, {useContext} from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {ClientLookUp} from "./clientLookup";
import {DocumentBuilderContext} from "../context";
import {grayColor, primaryColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {
    MarketAnalyticsDistrictSelect, MarketAnalyticsVBPRegionSelect,
    OpCoSelect,
    PresetModules
} from "./misc";
import {Check} from "@material-ui/icons";
import {IntroLauncher} from "../../intro_js";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {LinearProgress, MuiThemeProvider} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {MAX_DOCUMENT_LENGTH, RANDSTAD_THEME} from "../../config";
import MarketAnalyticsStep1 from "./marketAnalytics/marketAnalyticsStep1";


const Step1 = React.forwardRef((props, ref) => {
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    const [clientName] = React.useState();

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        sendState: () => {
            return sendState();
        },
        state: {
            clientName,
        },
    }));
    const sendState = () => {
        return {};
    };

    const isValidated = () => {
        switch (documentData.docType) {
            case "tender":
                return (documentData.filename.length > 0 || documentData.clientName) && documentData.opco;
            case "salesCollateral":
                return (documentData.filename.length > 0 || documentData.clientName) && documentData.opco;
            case "marketAnalytics":
                return documentData.filename.length > 0 && documentData.mainDistrict && documentData.professions.length > 0 && !documentData.professions.some(el => el.qualiKurz === "");
            default:
                console.log("error: no document type provided", documentData.docType)
        }
    };

    let showStep2 = (documentData.filename.length > 0 || documentData.clientName);
    let showStep3 = (documentData.filename.length > 0 && documentData.opco) || (documentData.clientName && documentData.opco);
    if (documentData.docType === "marketAnalytics") {
        if (documentData.filename.length > 0) {
            showStep3 = false;
        }
    }
    return <>
        <IntroLauncher action="welcome"/>
        <GridContainer>
            <GridItem xs={12} sm={4} id="pageOneStepOne">
                {/* only tender documents need to be able to be bound to a client
        all other documents (sales collateral and market analytics) receive solely a filename */}
                {documentData && <StepHeader step={1} completed={documentData.clientName}
                                             label={documentData.isTenderTeamMember ? "Kunde" : "Dateiname"}
                                             id="pageOneStepOneHeader"
                                             introAction={!documentData.isTenderTeamMember ? "pageOneStepOne" : "pageOneStepOneNonTender"}/>}
                {documentData.isTenderTeamMember && documentData.docType === "tender"
                    ? <ClientLookUp/>
                    : <CustomInput
                        labelText={<span>
              Wie soll deine Datei heißen?
            </span>}
                        id="pageOneStepOneFilenameInput"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            onChange: e => {
                                let newData = {...documentData};
                                newData.filename = e.target.value;
                                updateDocument(newData)
                            },
                            value: documentData.filename,
                        }}
                    />
                }
            </GridItem>
            <GridItem xs={showStep2 ? 12 : 1} sm={showStep2 ? 4 : 1} id="pageOneStepTwo" style={{
                border: `2px solid ${grayColor[4]}`,
                borderTop: "none",
                borderBottom: "none",
                visibility: showStep2 ? "visible" : "hidden"
            }}>
                <StepHeader step={2} completed={documentData.opco}
                            label={documentData.docType === "marketAnalytics" ? "Kreis" : "OpCo"}
                            introAction="pageOneStepTwo"/>
                {documentData.docType === "marketAnalytics"
                    ? <MarketAnalyticsStep1/>
                    : <OpCoSelect/>
                }
            </GridItem>

            <GridItem xs={12} sm={4} id="pageOneStepThree" style={{visibility: showStep3 ? "visible" : "hidden"}}>
                <StepHeader step={3} completed label="Themengebiete" introAction="pageOneStepThree"/>
                {documentData.docType && getStep3Headers(documentData.docType)}
                <DocumentLengthProgress/>
                {documentData.docType && <PresetModules/>}
            </GridItem>
        </GridContainer>
    </>;
});
Step1.displayName = "Index";
export default Step1;

function getStep3Headers(docType) {
    switch (docType) {
        case "tender":
            return <h6>Welche (optionalen) Themengebiete möchtest Du behandeln?</h6>;
        case "salesCollateral":
            return <h6>Welche Präsentationsthemen möchtest Du verwenden?</h6>;
        case "marketAnalytics":
            return <h6>Welche Folien möchtest Du verwenden?</h6>;
        default:
            console.log("error: no document type provided", docType)
    }
}


function StepHeader({step, completed, label, id, introAction}) {
    const containerStyle = {
        position: "relative",
        backgroundColor: grayColor[8],
        color: !completed ? grayColor[3] : primaryColor[0],
        padding: "1px 4px",
        textAlign: "center"
    }
    const numberStyle = {
        width: 30,
        height: 30,
        borderRadius: 15,
        marginRight: 10,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: !completed ? grayColor[4] : primaryColor[0],
        color: grayColor[8],
    }
    return <div style={containerStyle} id={id}>
        <IntroLauncher action={introAction}/>
        <h4 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <span style={numberStyle}>{completed ? <Check/> : step}</span>
            <span>{label}</span>
        </h4>
    </div>
}


export function DocumentLengthProgress() {
    const {documentData} = useContext(DocumentBuilderContext);
    let documentLength = documentData.templates.length;
    const getDocumentColor = () => {
        if (documentLength < 30) {
            return "success"
        } else if (documentLength < 40) {
            return "warning"
        } else {
            return "danger"
        }
    }
    const CustomProgress = withStyles((theme) => ({
        colorPrimary: {
            backgroundColor: RANDSTAD_THEME.palette.secondary.main,
        },
        bar: {
            backgroundColor: RANDSTAD_THEME.palette[getDocumentColor()].main,
        },
    }))(LinearProgress);

    let progressValue = (documentLength / MAX_DOCUMENT_LENGTH) * 100;
    if (progressValue > 100) {
        progressValue = 100;
    }

    return <div>
        <small>Beachte bitte, dass Präsentationen nicht länger als 40 Seiten sein dürfen.<br/>Du kannst diese zwar
            konfigurieren, aber nicht erstellen.</small>
        <CustomProgress variant="determinate" color="primary" value={progressValue}/>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <small>Länge Deiner Präsentation</small>
            <small>{documentLength}/{MAX_DOCUMENT_LENGTH} Seiten</small>
        </div>
    </div>
}

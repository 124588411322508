import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';
import {grayColor, primaryColor, successColor} from "../../../../assets/jss/material-dashboard-pro-react";
import {DocumentBuilderContext} from "../context";
import {Add, Check, Close, Visibility} from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "../../../../components/CustomButtons/Button";
import Popover from '@material-ui/core/Popover';
import {firebaseDB} from "../../../../firebase/config";
import {onValue, ref as firebase_ref} from "firebase/database";


const useRowStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.04)"
    },
    padding: 8,
    borderBottom: "1px solid rgba(0,0,0,.1)",
    height: 80,
    overflowY: "scroll"
  },
  fileName: {
    fontWeight: 700,
    margin: 0,
    width: "90%",
    cursor: "pointer"
  },
  fileDescription: {
    fontSize: 12,
    opacity: .8,
  },
  addButton: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    height: 24,
    width: 24,
    fontSize: 12,
    top: 4,
    right: 4,
    color: primaryColor[0],
    cursor: "pointer",
    "&:hover": {
      background: primaryColor[0],
      color: "#fff",
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(33 117 217 / 40%)"
    }
  },
  isSelectedIcon: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    height: 24,
    width: 24,
    fontSize: 12,
    top: 4,
    right: 4,
    color: successColor[0],
  },
  isPreviewedIcon: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    height: 24,
    width: 24,
    fontSize: 12,
    top: 4,
    right: 30,
    color: grayColor[3],
  },
  badge: {
    fontSize: ".75rem",
    background: grayColor[4],
    color: grayColor[1],
    padding: "2px 4px",
    marginRight: 4,
    borderRadius: 4
  }
}));

function renderRow(props) {
  const classes = useRowStyles()
  const { documentData, updateDocument } = useContext(DocumentBuilderContext);
  const [showCreateNewDialog, setShowCreateNewDialog] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null); // used for popup
  const [thumbnail, setThumbnail] = React.useState(null);
  const item = props.data[props.index];
  const isSelected = documentData.templates.some( el => el.id === item.id);

  useEffect(()=>{
    if (open){
      const db = firebaseDB;
      let endpoint = ""
      switch(documentData.docType){
        case "tender":
          endpoint = 'templates/thumbnails'
          break;
        case "salesCollateral":
          endpoint = "salesCollateralTemplates/thumbnails";
          break;
        case "marketAnalytics":
          endpoint = "marketAnalyticsTemplates/thumbnails";
          break;
        default:
          console.log("error: no document type provided", documentData.docType);
      }
      const thumbnailRef = firebase_ref(db, `${endpoint}/${item.id}`);
      console.log("getting thumbnail", item.id);
      onValue(thumbnailRef, (snapshot) => {
        setThumbnail(snapshot.val());
      }, {
        onlyOnce: true
      })
    }
  })

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "popover_" + item.id : undefined;

  const handleChooseTemplate = () => {
    if (item.id === -1){
      setShowCreateNewDialog(true);
      return
    }
    let {templates} = documentData;
    templates.push(item);
    let newDoc = {
      ...documentData,
      templates
    };
    updateDocument(newDoc);

  }
  const handlePreview = (e) => {
    setIsPreviewed(true);
    setAnchorEl(e.currentTarget);
  }
  let itemName = item.name.replace("Angebotsvorlage Randstad Gruppe", "").trim();
  return (
    <div style={{...props.style}} key={props.index} className={classes.row}>
      <p className={classes.fileName + " templateLookup_itemName"} onClick={handlePreview}>{itemName}</p>
      <p className={classes.fileDescription}>{item.description}</p>
      <div style={{ lineBreak: 'anywhere' }}>
        {item.opcos && item.opcos.map( (opco, key) => <span key={key} className={classes.badge}>{opco.replace(/_/g, " ")}</span>)}
        {item.topics && item.topics.map( (topic, key) => <span key={key} className={classes.badge}>#{topic}</span>)}
      </div>
      {!isSelected
        ? <div className={classes.addButton + " templateLookup_addItem"} onClick={handleChooseTemplate}>
          <Add fontSize="small"/>
        </div>
        : <div className={classes.isSelectedIcon}>
          <Check fontSize="small"/>
        </div>
      }
      {isPreviewed && <div className={classes.isPreviewedIcon}>
        <Visibility fontSize="small"/>
      </div>}
      <CreateNewTemplateDialog isOpen={showCreateNewDialog} handleOnClose={ () => setShowCreateNewDialog(false)} />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div style={{width: 400, height: 225, backgroundImage: `url("data:image/png;base64,${thumbnail}")`, backgroundSize: "contain", backgroundRepeat: "no-repeat"}}/>
      </Popover>
    </div>
  );
}


const useListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    border: `2px solid ${primaryColor[0]}`,
    borderTop: "none",
    marginTop: -18
  },
  row: {
    display: "flex",
    flexDirection: "column",
  }
}));

export default function TemplateList({templates}) {
  const classes = useListStyles();

  return (
    <div className={classes.root} id="templateList">
      <FixedSizeList itemData={templates} itemCount={templates.length} height={400} itemSize={120} onClick={el => console.log(el)}>
        {renderRow}
      </FixedSizeList>
    </div>
  );
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateNewTemplateDialog({openFile, isOpen, handleOnClose}){
  return <div>
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOnClose}
      aria-labelledby="openFile-title"
      aria-describedby="openFile-description"
    >
      <DialogTitle id="openFile-title">
        Neues Template erstellen?
        <div style={{position: "absolute", top: 14, right: 14, cursor: "pointer" }} onClick={handleOnClose}><Close/></div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="openFile-description">
          Nichts passendes gefunden? Möchtest du ein neues Modul zu deinem Suchbegriff anfordern?
        </DialogContentText>

        <DialogActions style={{display: "flex", justifyContent: "space-between"}}>
          <Button simple onClick={handleOnClose} color="danger">
            Abbrechen
          </Button>
          <Button onClick={()=>window.open("https://docs.google.com/forms/d/e/1FAIpQLScMBuPSqQ3jQWl-H288GFjFbt6q54u-HxVAxsP1daxUcsJAvQ/viewform")} color="primary">
            Modul anfordern
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  </div>
}
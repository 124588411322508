import { initializeApp } from "firebase/app";
import {getDatabase} from "firebase/database";
import {env} from "../views/ProposalBuilder/config";


export const GOOGLE_DRIVE_SCOPES = [
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.metadata',
  'https://www.googleapis.com/auth/drive.metadata.readonly',
  //'https://www.googleapis.com/auth/drive.photos.readonly',
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/drive.scripts',
]

export const GOOGLE_oAuth_CLIENT_ID = env === "prod" ? "495902017333-84pcu1t6igriajujule1eeibigk5dte8.apps.googleusercontent.com" : "696587410884-64pmpdr53gea9rasg9ckb2q9jcfk0npj.apps.googleusercontent.com";

const firebaseConfig__prod = {
  apiKey: "AIzaSyB4cTU0XBq1UNfC5nlWQx8jf_wmtD7aGJo",
  authDomain: "derd-proptool-prd-499f.firebaseapp.com",
  databaseURL: "https://derd-proptool-prd-499f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "derd-proptool-prd-499f",
  storageBucket: "derd-proptool-prd-499f.appspot.com",
  messagingSenderId: "495902017333",
  appId: "1:495902017333:web:370f84481674b391da6e7c",
  measurementId: "G-203NXTWNHY"
};

const firebaseConfig__dev = {
  apiKey: "AIzaSyCv-JER9BsoZY0aPZDmHe-SavG9IbLodEY",
  authDomain: "derd-proptool-dev-31a0.firebaseapp.com",
  databaseURL: "https://derd-proptool-dev-31a0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "derd-proptool-dev-31a0",
  storageBucket: "derd-proptool-dev-31a0.appspot.com",
  messagingSenderId: "696587410884",
  appId: "1:696587410884:web:6340cc772d0a6b2e901891"
};

const app = initializeApp(env === "prod" ? firebaseConfig__prod : firebaseConfig__dev);
export const firebaseDB = getDatabase(app);
export default app;

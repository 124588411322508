import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {getAuth, GoogleAuthProvider, signInWithCredential} from "firebase/auth";
import {GoogleLogin} from "react-google-login";
import {Redirect} from "react-router-dom";
import {GOOGLE_DRIVE_SCOPES, GOOGLE_oAuth_CLIENT_ID} from "../../firebase/config";
import {VALID_GROUP_EMAIL_DOMAINS} from "./config";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [cookies, setCookie] = useCookies(['name']);
  const [unauthorizedAlert, setUnauthorizedAlert] = React.useState(false);

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);

  const handleGoogleResponse = async (googleUser) => {
    // ensure only randstad group can login
    if (googleUser && googleUser.profileObj){
      let email = googleUser.profileObj.email;
      let isValidEmail = VALID_GROUP_EMAIL_DOMAINS.indexOf(email.split("@")[1]) > -1;
      if (isValidEmail){
        const credential = GoogleAuthProvider.credential(googleUser.getAuthResponse().id_token);
        const auth = getAuth();
        signInWithCredential(auth, credential).then(res => {
          if (googleUser){
            setCookie("googleOAuthAccessToken", googleUser.tokenObj.access_token);
            setCookie("googleOAuthAccessTokenExpirationTimestamp", googleUser.tokenObj.expires_at);
          }
          return <Redirect to="/admin" />
        }).catch(err => {
          console.log("ERROR AUTHENTICATING: ", err)
        });
        setUnauthorizedAlert(false);
      } else {
        setUnauthorizedAlert(true);
      }
    }
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <p>Willkommen beim Randstad SalesPekka, dem Tool zur Angebotserstellung. Für die Nutzung musst Du angemeldet sein.</p>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GoogleLogin
                  clientId={GOOGLE_oAuth_CLIENT_ID}
                  scope={GOOGLE_DRIVE_SCOPES.join(" ")}
                  buttonText="Anmelden..."
                  onSuccess={handleGoogleResponse}
                  onFailure={handleGoogleResponse}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => <Button color="primary" block onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    Mit Google anmelden
                  </Button>}
                />
              </CardFooter>
              {unauthorizedAlert && <CardFooter className={classes.justifyContentCenter}>
                <Alert severity="error">Du bist nicht zugangsberechtigt!</Alert>
              </CardFooter>}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

/*
curl \
  'https://www.googleapis.com/drive/v3/files/1e9HLVTgYZq-MeLJ8tNql8wp_cVr16wXDbJA_v7iagLw?key=AIzaSyCuwkqMOEEqy089oWP6_va2Iws4V3utnwo' \
  --header 'Authorization: Bearer ya29.a0ARrdaM9EzsdV0207e90g5Gi-Px4wtkc2XK5PU_K2IX9mOlDCWqnig008JUdyiwQK-mY4MVtHm6afFXTutJfeGZFAUvlWuqxeycNTHzje87wgllvXYzrVtceNPPSaiCsNb6XrRoICR_VCwT6bg_MkySuxaewjbA' \
  --header 'Accept: application/json' \
  --compressed

 */
import React, {useContext, useEffect, useState} from "react";
import Step1 from "./WizardSteps/Step1/index";
import Step2 from "./WizardSteps/Step2/index";
import Wizard from "../../components/Wizard/Wizard";
import {DocumentBuilderContext, DocumentBuilderProvider} from "./WizardSteps/context";
import {
    dangerColor,
    grayColor,
    primaryColor,
    successColor
} from "../../assets/jss/material-dashboard-pro-react";
import modalStyles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import {colors, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Slide} from "@material-ui/core";
import {
    AppScriptSecret,
    CloudFunctionAuthSecret,
    DEAL_TEAM_ROLES,
    OpCoOPTIONS,
    GCP_PROJECT_ID,
    MARKET_ANALYTICS_USERS, APP_SCRIPT_DEPLOYMENT_ID, ADMINS
} from "./config";
import {makeStyles} from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import axios from "axios";
import {AuthContext} from "../../firebase/context";
import {CheckCircleSharp, CloseSharp, ThumbUpAlt} from "@material-ui/icons";
import {
    normalizeTextTag,
    tagIsHeSheGenderfication,
    tagIsPositionName,
    tagIsPositionTitle,
    tagIsAddressBlock, getAllDealTeamRoleTags, tagIsMarketAnalyticsDistrict
} from "../../components/helpers";
import Snackbar from "../../components/Snackbar/Snackbar";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import customSelectStyles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import useDynamicRefs from 'use-dynamic-refs';
import AutoCompleteAsync from "../../components/AutoComplete/AutoCompleteAsync";
import LoadingScreenStep1 from "./WizardSteps/Step1/loadingScreen";
import customCheckboxRadioSwitchStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Switch from "@material-ui/core/Switch";
import {CountDownTimer} from "./WizardSteps/CreationCountdown/CountDownTimer";
import {getDatabase, ref, set} from "firebase/database";
import {v4 as uuidv4} from "uuid";
import DeleteTemplates from "./TemplateManager/DeleteTemplates";


export default function SalesPekka() {
    return <div>
      <DocumentBuilderProvider>
        <PBContainer/>
      </DocumentBuilderProvider>
    </div>;
}

function PBContainer() {
    const {documentData, updateDocument, templateLibrary, loadUserProfile} = useContext(DocumentBuilderContext);
    const [loading, setLoading] = useState(true);
    const [creatingDocument, setCreatingDocument] = useState(false);
    const [documentCreated, setDocumentCreated] = useState(null);
    const [finalizeDocument, setFinalizeDocument] = useState(false);
    const [creationStartingTimeStamp, setCreationStartingTimeStamp] = useState(null);
    const [progressTrackerId, setProgressTrackerId] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        getTenderTeam();
        // load user profile...
        loadUserProfile();
    }, [])

    const handleDocumentTypeSelection = (type) => {
        let newData = {...documentData, docType: type};
        switch (type) {
            case "tender":
                updateTemplates("updateTenderTemplates");
                break;
            case "salesCollateral":
                updateTemplates("updateSalesCollateralTemplates");
                newData.isTenderTeamMember = false;
                break;
            case "marketAnalytics":
                updateTemplates("updateMarketAnalyticsTemplates");
                newData.isTenderTeamMember = false;
                newData.district = [];
                newData.mainDistrict = null;
                newData.professions = [];
                break;
            default:
                console.log("error: no document type provided", documentData.docType)
        }
        setDocumentType(type);
        updateDocument(newData);
    }

    const getTenderTeam = () => {
        axios({
            url: `https://script.google.com/a/macros/gulp.de/s/AKfycbxZeJom6nxaUDqc0gdMM4ZOBLaRgsRo52UPbyzLDtD8QhsOn3-LSnTCr1JY8e5T0rOS/exec?authToken=${AppScriptSecret}&requestType=groupMembers&groupname=tendermanagement@randstad.de`
        }).then(r => {
            let newData = {...documentData};
            let isTenderTeamMember = r.data.includes(user.email);
            newData.isTenderTeamMember = isTenderTeamMember;
            updateDocument(newData);
            if (isTenderTeamMember) {
                updateClients()
            }
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);
        })
    }
    const updateClients = () => {
        axios({
            url: `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/updateFirebaseRTDB-noAuth`,
            headers: {
                "Authorization": `Bearer ${CloudFunctionAuthSecret}`,
            },
            params: {
                action: 'updateClients'
            }
        }).then(r => {
            console.log("updated clients")
        }).catch(err => console.log("error updating clients", err))
    }
    const updateTemplates = (action) => {
        console.log("running", action)
        axios({
            url: `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/updateFirebaseRTDB-noAuth`,
            headers: {
                "Authorization": `Bearer ${CloudFunctionAuthSecret}`,
            },
            params: {
                action
            }
        }).then(r => {
            console.log("completed running:", action)
        }).catch(err => console.log("error updating templates", err))
    }

    const submit = () => {
        // create progressTracker
        console.log("submitting")
        let trackerUUID = uuidv4();
        //setProgressTrackerId(trackerUUID);
        //const db = getDatabase();
        //set(ref(db, "progressTrackers/" + trackerUUID), "Dokumentenerstellung gestartet...")
        setCreationStartingTimeStamp(Date.now())
        let data = {
            dealTeam: documentData.dealTeam,
            tenderFolder: documentData.tenderFolder,
            templates: documentData.templates.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    tags: el.tags,
                    multipleProfessions: el.description.includes("#multipleProfessions")
                }
            }),
            district: documentData.docType === "marketAnalytics" ? documentData.district : null,
            mainDistrict: documentData.docType === "marketAnalytics" ? documentData.mainDistrict : null,
            professions: documentData.docType === "marketAnalytics" ? documentData.professions : null,
            opco: documentData.opco ? documentData.opco : "randstad_deutschland",
            userThumbnails: documentData.userThumbnails,
            userAddresses: documentData.userAddresses,
            filename: documentData.filename,
            progressTrackerId: trackerUUID
        };
        console.log(user, ADMINS)
        if (ADMINS.includes(user.email)){
            debugger;
            console.log("this is the data", data);
        }
        startDocumentCreation(data);
    }

    async function startDocumentCreation(data) {
        setFinalizeDocument(false);
        setCreatingDocument(true);
        try {
            const response = await axios.post(
                `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/create_document_2_0`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${CloudFunctionAuthSecret}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            debugger;
            let doc_data = response.data;
            let counter = 1;
            while (counter < Math.ceil(doc_data.temp_file_ids.length)) {
                await editDocumentInAppScript(doc_data, counter);
                counter += 10;
            }
            await finalizeDocumentCreation(doc_data);
        } catch (e) {
            console.error(e);
            setFinalizeDocument(false);
            setCreatingDocument(false);
        }
    }

    async function editDocumentInAppScript(data, start) {
        let end = start + 10;
        console.log(`creating document round ${start}-${end} of ${data.temp_file_ids.length}`);
        data = {
            'main_file_id': data.main_file_id,
            'template_ids': data.temp_file_ids.slice(start, end),
            'progress_tracker_id': data.progress_tracker_id
        };
        let url = `https://script.google.com/a/macros/gulp.de/s/${APP_SCRIPT_DEPLOYMENT_ID}/exec?authToken=${AppScriptSecret}&requestType=buildTenderPresentation`;
        return await axios.post(
            url,
            JSON.stringify(data),
            {
                'Content-Type': 'text/plain'
            }
        )
    }

    async function finalizeDocumentCreation(data) {
        data.docType = documentType;
        if (documentType === "marketAnalytics") {
            let endpoint = 'https://derd-market-analytics-salespek.herokuapp.com/screenshot/get_all?token=';
            endpoint += "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
            endpoint += `&queries=${documentData.marketAnalyticsQualification}->${documentData.district.district}&area_code=${documentData.district.value}&file_id=${data.main_file_id}&vbp_region=${documentData.vbp_district}&user=${user.email}`;
            data.marketAnalyticsImageGenerationEndpoint = endpoint;
        }
        let url = `https://script.google.com/a/macros/gulp.de/s/${APP_SCRIPT_DEPLOYMENT_ID}/exec?authToken=${AppScriptSecret}&requestType=finalizeTenderPresentation`;
        try {
            let res = await axios.post(
                url,
                JSON.stringify(data),
                {
                    'Content-Type': 'text/plain'
                }
            )
            deleteTempFiles(data.temp_file_ids, data.progress_tracker_id);
        } catch (error) {
            // for some reason a 302 error causes this error, but we'll ignore it for now...
            deleteTempFiles(data.temp_file_ids, data.progress_tracker_id);
            console.log("error finalizing document", error);
        }
    }

    const deleteTempFiles = (temp_file_ids, progress_tracker_id) => {
        console.log("deleting temp files now...")
        axios({
            url: `https://europe-west3-${GCP_PROJECT_ID}.cloudfunctions.net/create_document?substep=deleteTempFiles`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${CloudFunctionAuthSecret}`,
            },
            data: {
                temp_file_ids: temp_file_ids,
                progress_tracker_id: progress_tracker_id
            }
        }).then(r => {
            setCreatingDocument(false);
            setDocumentCreated("success");
        }).catch(err => {
            console.log(err);
            setCreatingDocument(false);
            setDocumentCreated("success");
        })
    }

    const isValidated = () => {
        let _isValidated = true;
        documentData.templates.filter(el => !el.description.includes("#multipleProfessions")).forEach(template => {
            if (template.tags.textTags && template.tags.textTags
                .filter(tag => (!tagIsPositionName(tag) && !tagIsPositionTitle(tag) && !tagIsAddressBlock(tag)))
                .filter(tag => !tagIsHeSheGenderfication(tag))
                .filter(tag => !tagIsMarketAnalyticsDistrict(tag))
                .some(el => el.value.length < 1)) {
                _isValidated = false
            }
            if (template.tags.selectionTags && template.tags.selectionTags.some(el => el.value.length < 1)) {
                _isValidated = false;
            }
        })
        return _isValidated
    }

    return <div>
        {loading && <LoadingScreenStep1/>}
        <DocumentTypePicker isOpen={documentType === null} onClose={handleDocumentTypeSelection}/>
        {documentType && documentData.isTenderTeamMember && <ToggleIsTender/>}
        {documentType && <Wizard
            validate={documentData.docType !== "marketAnalytics"}
            steps={[
                {stepName: "Allgemein", stepComponent: Step1, stepId: "general"},
                {stepName: "Konfigurator", stepComponent: Step2, stepId: "templateGallery"},
            ]}
            title="Dokumentenerstellung"
            subtitle={documentData.clientName
                ? <span>Angebot {documentData.tenderFolder && <a id="tenderFolderHyperlink"
                                                                 href={`https://drive.google.com/drive/u/0/folders/${documentData.tenderFolder && documentData.tenderFolder.id}`}
                                                                 target="_blank"
                                                                 style={{fontWeight: 700, color: primaryColor[0]}}
                >{documentData.tenderFolder.name}</a>}</span>
                : documentData.filename.length > 0
                    ? <span style={{fontWeight: 700, color: primaryColor[0]}}>{documentData.filename}</span>
                    : "Für wen und was soll dieses Angebot dienen?"
            }
            finishButtonClick={() => {
                console.log("click")
                if (getAllDealTeamRoleTags(documentData.templates).length > 0) {
                    setFinalizeDocument(true)
                } else {
                    submit()
                }
            }}
            previousButtonText="zurück"
            nextButtonText="weiter"
            nextButtonClasses="introjs__nextButton"
            finishButtonText={isValidated()
                ? (getAllDealTeamRoleTags(documentData.templates).length > 0 ? "Dokument überprüfen" : "Dokument erstellen")
                : "Es sind noch nicht alle Tags ausgefüllt."
            }
            isCompleted={!isValidated()}
        />}

        {/* TODO: only allow creation when max size is correct*/}

        <InvalidOpcoAlert/>

        {documentData.docType && ADMINS.includes(user.email) && <DeleteTemplates
            docType={documentData.docType}
        />}

        <FinalizingDocumentModal
            isOpen={finalizeDocument}
            submit={submit}
            close={() => setFinalizeDocument(false)}
        />
        <CreatingDocumentModal
            isOpen={creatingDocument}
            templates={documentData.templates.map(templ => templ.name)}
            startingTimestamp={creationStartingTimeStamp}
            progressTrackerId={progressTrackerId}
        />
        <Snackbar
            message={documentCreated === "success" ?
                <p>Dein Dokument wurde erfolgreich erstellt!<br/>Du erhältst es jeden Moment per E-Mail.</p> :
                <p>Ups! Da ist etwas schief gegangen.<br/>Bitte überprüfe Dein Dokument noch einmal.</p>}
            color={documentCreated === "success" ? "success" : "danger"}
            close={true}
            place="tc"
            open={documentCreated !== null}
            closeNotification={() => setDocumentCreated(null)}
        />
        {user.email === "shezan.kazi@gulp.de" &&
            <button onClick={() => console.log(documentData)}>documentData</button>}
    </div>
}


const useModalStyles = makeStyles(modalStyles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function InvalidOpcoAlert() {
    const classes = useModalStyles();
    const {documentData} = useContext(DocumentBuilderContext);
    const currentOpco = documentData.opco;
    let isOpen = false
    let alert = "";
    let requestable = false;

    if (currentOpco) {
        let obj = OpCoOPTIONS.find(el => el.value === currentOpco);
        isOpen = documentData.isTenderTeamMember ? !(obj.isSupported || obj.tenderTeamOnly) : !obj.isSupported;
        alert = obj.alert;
        requestable = obj.requestable;
    }

    const sendMailToTenderManagement = () => {
        let subject = "Neues Angebot für " + documentData.clientName;
        let body = `Liebe Kollegen aus dem Tender Management,\n\nich würde gerne ein Angebot für die Firma ${documentData.clientName} und bräuchte dazu eure Unterstützung.`
        let url = `mailto:tenderservices@randstad.de?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(url, "_blank")
    }

    return <Dialog
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        open={isOpen}
        transition={Transition}
        keepMounted
        onClose={() => true}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
        >
            <h5>{alert}</h5>
        </DialogContent>
        <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
            <Button onClick={() => window.location.reload()}>
                Neu anfangen
            </Button>
            {requestable && <Button color="primary" onClick={sendMailToTenderManagement}>
                Email an Tender Management
            </Button>}
        </DialogActions>
    </Dialog>
}

const useCreatingDocumentModalStyles = makeStyles({
    ...modalStyles
})

function CreatingDocumentModal({isOpen, templates, startingTimestamp, progressTrackerId}) {
    const classes = useCreatingDocumentModalStyles();

    return <Dialog
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        open={isOpen}
        transition={Transition}
        keepMounted
        onClose={() => true}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
        >
            <h2 style={{textAlign: "center"}}>Dein Angebot wird erstellt...</h2>

            {isOpen && <CountDownTimer templates={templates} startingTimestamp={startingTimestamp}
                                       progressTrackerId={progressTrackerId}/>}

            <small style={{opacity: .5}}>Das wird einen Moment dauern. Sobald alles fertig ist, bekommst Du eine
                Email.<br/>
                <strong style={{color: dangerColor[0]}}>Bitte schließe das Fenster nicht, bis das Dokument fertig ist -
                    sonst geht Deine Arbeit verloren.</strong></small>
        </DialogContent>
    </Dialog>
}


const useModalStyles2 = makeStyles({
    ...modalStyles,
    ...customSelectStyles,
    closeButton: {
        position: "absolute",
        right: 14,
        top: 14,
        cursor: "pointer",
        color: grayColor[0],
        "&:hover": {
            color: grayColor[2]
        }
    }
})

function FinalizingDocumentModal({isOpen, submit, close}) {
    const classes = useModalStyles2();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    const [getRef, setRef] = useDynamicRefs();
    const [positionTags, setPositionTags] = useState([]);
    const [isCompleted, setIsCompleted] = useState([]);

    useEffect(() => {
        let _isCompleted = {};
        getAllDealTeamRoleTags(documentData.templates).forEach(tag => {
            _isCompleted[tag.tag] = _nameInputIsCompleted(tag) && _selectIsCompleted(tag);
            if (_positionInputIsCompleted(tag) !== null) {
                if (_positionInputIsCompleted(tag) === false) {
                    _isCompleted[tag.tag] = false
                }
            }
        })
        setIsCompleted(_isCompleted);
        getAllPositionTags();
    }, [documentData])


    const getAllPositionTags = () => {
        let positionTags = []
        getAllDealTeamRoleTags(documentData.templates).forEach(nameTag => {
            let tag = nameTag.tag.replace("{{ ", "{{").replace(" }}", "}}").replace("_NAME}}", "").replace("{{", "");
            documentData.templates.forEach(template => {
                template.tags.textTags && template.tags.textTags.forEach(positionTag => {
                    if (tagIsPositionTitle(positionTag)) {
                        if (positionTag.tag.includes(tag)) {
                            positionTags.push(positionTag.tag)
                        }
                    }
                })
            })
        })
        setPositionTags(positionTags)
    }

    const showPositionTag = (nameTag) => {
        let tag = nameTag.replace("{{", "").replace("_NAME}}", "_TITEL");
        return positionTags.indexOf("{{" + tag + "}}") !== -1;
    }

    const handleInputChange = (val, tag, isTitle) => {
        let newData = {...documentData};
        let normalizedTag = normalizeTextTag(tag.tag);
        if (isTitle) {
            normalizedTag = normalizeTextTag(tag.tag.replace("_NAME}}", "_TITEL}}"));
        }
        // let's update all instances of this tag
        newData.templates.forEach(template => {
            // first we'll replace all textTags
            template.tags.textTags && template.tags.textTags.forEach(textTag => {
                let _normalizedTag = normalizeTextTag(textTag.tag);
                if (normalizedTag === _normalizedTag) {
                    textTag.value = val;
                }
            })
            // next we'll update all selectionTags
            template.tags.selectionTags && template.tags.selectionTags.forEach(selectionTag => {
                let normalizedSelectionTag = normalizeTextTag(selectionTag.tag);
                if (normalizedSelectionTag.includes(normalizedTag)) {
                    selectionTag.variableValue = val
                }
            })
        })
        updateDocument(newData);
    }
    const getInputValue = (tag, isTitle) => {
        let text = "";
        let normalizedTag = normalizeTextTag(tag.tag);
        if (isTitle) {
            normalizedTag = normalizeTextTag(tag.tag.replace("_NAME}}", "_TITEL}}"));
        }
        documentData.templates.find(template => {
            return template.tags.textTags && template.tags.textTags.find(textTag => {
                if (normalizeTextTag(textTag.tag) === normalizedTag) {
                    text = textTag.value;
                    return
                }
                return false
            })
        })
        return text
    }

    const handleSelectChange = (val, tag) => {
        // a tag is something like {{ ACCOUNT_SPECIALIST_NAME }}
        // however the gender tag will be e.g. #HE_SHE_ACCOUNT_SPECIALIST
        // therefore we need to match the two on ACCOUNT_SPECIALIST
        let tagReference = DEAL_TEAM_ROLES.find(dtr => tag.tag.includes(dtr.replace(/ /g, "_").toUpperCase()));
        tagReference = tagReference.replace(/ /g, "_").toUpperCase();
        let newData = {...documentData};
        newData.templates.forEach(template => {
            // 1st we replace all he:she tags, e.g. #HE_SHE_ACCOUNT_SPECIALIST
            template.tags.textTags && template.tags.textTags.filter(textTag => tagIsHeSheGenderfication(textTag)).forEach(textTag => {
                if (textTag.tag.includes(tagReference)) {
                    textTag.value = val === "male" ? "er" : "sie";
                }
            })
            // 2nd we replace all his:her tags, e.g. {% IF $PROCESS_MANAGER__GENDER_F %} ihr {% ELSE %} sein {% ENDIF %}
            template.tags.booleans && template.tags.booleans.filter(booleanTag => booleanTag.tag.includes("__GENDER_F")).forEach(booleanTag => {
                if (booleanTag.tag.includes(tagReference)) {
                    booleanTag.value = val !== "male";
                }
            })
        });
        updateDocument(newData);
    }

    const _nameInputIsCompleted = (tag) => {
        let completed = false;
        documentData.templates.forEach(template => {
            template.tags.textTags && template.tags.textTags.forEach(textTag => {
                if (normalizeTextTag(textTag.tag) === normalizeTextTag(tag.tag)) {
                    completed = textTag.value && textTag.value.length > 0
                }
            })
        })
        return completed
    }

    const _positionInputIsCompleted = (tag) => {
        let ref = getRef("position_input_" + tag.tag);
        return ref && ref.current && ref.current.firstElementChild.value !== "";
    }

    const _selectIsCompleted = (tag) => {
        let ref = getRef("select_" + tag.tag);
        return ref && ref.current && ref.current.value !== undefined;
    }

    const updateUserMeta = (user, tag) => {
        tag = normalizeTextTag(tag.tag);
        tag = tag.replace("_name}}", "_img}}").toUpperCase();
        let newData = {...documentData};
        // first update thumbnails
        if (newData.userThumbnails) {
            newData.userThumbnails[tag] = user.thumbnail
        } else {
            newData.userThumbnails = {
                [tag]: user.thumbnail
            }
        }
        // then update addresses
        tag = tag.replace("_IMG}}", "_ADDRESS_BLOCK}}");
        tag = tag.replace("{{", "{{#");
        let organization = user.organizations && user.organizations.find(org => org.primary);
        let address = user.addresses && user.addresses.find(addr => addr.city && addr.city.toLowerCase() === organization && organization.location.toLowerCase());
        let email = user.email;
        let phone = user.phones && user.phones.find(tel => tel.type === "work");
        if (!phone) {
            phone = user.phones && user.phones.find(tel => tel.type === "mobile");
        }
        if (!phone) {
            phone = null
        } else {
            phone = phone.number;
        }
        if (!address) {
            address = user.addresses && user.addresses[0];
        }
        if (newData.userAddresses) {
            newData.userAddresses[tag] = {
                organization,
                address,
                email,
                phone
            }
        } else {
            newData.userAddresses = {
                [tag]: {
                    organization,
                    address,
                    email,
                    phone
                }
            }
        }
        updateDocument(newData)
    }

    let url = `https://script.google.com/a/macros/gulp.de/s/AKfycbyzDCKPRuucCktqaE4HQNppI32SbpXKb64m9hOcQsqQiO_EoV7eMq6-bLvazW8whght/exec?authToken=${AppScriptSecret}`;

    return <Dialog
        fullWidth={true}
        maxWidth="md"
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        open={isOpen}
        transition={Transition}
        keepMounted
        onClose={() => true}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        {getAllDealTeamRoleTags(documentData.templates).length > 0 && <DialogTitle className={classes.modalTitle}>
            <CloseSharp className={classes.closeButton} onClick={close}/>
            <p>Abschließende Angaben</p>
            <p style={{fontSize: 14}}>Wir haben in deinem Dokument Angaben zu Personen gefunden. Damit wir das Dokument
                erstellen können, benötigen wir noch folgende Angaben:</p>
            <p style={{fontSize: 12, color: grayColor[0]}}>Sollte die jeweilige Person noch nicht bekannt sein oder
                feststehen, kannst Du die Reihe auch einfach leer lassen.</p>
        </DialogTitle>}

        <DialogContent className={classes.modalBody}>
            {getAllDealTeamRoleTags(documentData.templates).map((tag, key) => <GridContainer key={key}
                                                                                             style={{alignItems: "center"}}>
                <GridItem xs={4}>
                    <AutoCompleteAsync
                        style={{marginBottom: 4}}
                        key={key}
                        customAppendedOptions={[{
                            firstName: "UNBEKANNT",
                            lastName: "",
                            organizations: [{primary: true, title: ""}],
                            gender: {type: "male"}
                        }]}
                        autoComplete={true}
                        url={url}
                        name={tag.tag.replace("{{", "").replace("}}", "")}
                        labelKey={el => el.firstName + " " + el.lastName}
                        optionRenderer={el => {
                            const org = el.organizations && el.organizations.find(el => el.primary);
                            return <div>
                                <p style={{marginBottom: 0}}>{el.firstName} {el.lastName}</p>
                                {org && <small style={{color: grayColor[0]}}>
                                    <strong>{org.title}</strong><br/>
                                    {org.name}
                                </small>}
                            </div>
                        }}
                        handleChange={user => {
                            let fullName = user.firstName + " " + user.lastName;
                            let title = user.organizations.find(org => org.primary).title;
                            handleInputChange(fullName, tag, false);
                            handleInputChange(title, tag, true);
                            updateUserMeta(user, tag);
                        }}
                    />
                    {/*<CustomInput
            labelText={tag.tag.replace("{{", "").replace("}}", "")}
            id={`template_name_tag-${tag.tag}_${key}`}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: e => handleInputChange(e.target.value, tag, false),
              ref: setRef("name_input_" + tag.tag)
            }}
          />*/}
                </GridItem>

                <GridItem xs={4}>
                    {showPositionTag(tag.tag) && <CustomInput
                        labelText={tag.tag.replace("{{", "").replace("_NAME}}", "_TITEL")}
                        id={`template_position_tag-${tag.tag}_${key}`}
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                            onChange: e => handleInputChange(e.target.value, tag, true),
                            ref: setRef("position_input_" + tag.tag),
                            value: getInputValue(tag, true)
                        }}
                    />}
                </GridItem>

                <GridItem xs={3}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor={tag.tag + "_text_tag"} className={classes.selectLabel}>
                            Anrede wählen...
                        </InputLabel>

                        <Select
                            MenuProps={{className: classes.selectMenu}}
                            classes={{select: classes.select}}
                            onChange={e => handleSelectChange(e.target.value, tag)}
                            inputProps={{
                                name: tag.tag + "Text",
                                id: tag.tag + "_text_tag",
                                ref: setRef("select_" + tag.tag)
                            }}
                        >
                            <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                                Anrede wählen...
                            </MenuItem>
                            <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                      value="male">
                                Er:sein
                            </MenuItem>)}
                            <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                      value="female">
                                Sie:ihr
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={1} style={{display: "flex", alignItems: "center"}}>
                    {isCompleted[tag.tag]
                        ? <CheckCircleSharp style={{color: successColor[0]}}/>
                        : <CheckCircleSharp color="disabled"/>
                    }
                </GridItem>
            </GridContainer>)}
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}
                       style={{justifyContent: "center"}}>
            {Object.keys(isCompleted).every(key => isCompleted[key])
                ? <Button color="primary" onClick={submit}>
                    Fertig <ThumbUpAlt style={{margin: "0px 8px"}}/> Dokument jetzt erstellen!
                </Button>
                : <Button simple color="warning">
                    Bitte vervollständige deine Angaben, damit wir das Dokument erstellen können.
                </Button>
            }
        </DialogActions>
    </Dialog>
}

const useSwitchStyles = makeStyles({
    root: {
        position: "absolute",
        zIndex: 999,
        marginTop: "2rem",
        display: "flex",
        alignItems: "center"
    },
    ...customCheckboxRadioSwitchStyles
});

function ToggleIsTender() {
    const classes = useSwitchStyles();
    const {documentData, updateDocument} = useContext(DocumentBuilderContext);
    const handleChange = () => {
        let newData = {...documentData};
        newData.isTenderTeamMember = !newData.isTenderTeamMember;
        updateDocument(newData);
    }
    return <div className={classes.root}>
        <Switch
            checked={documentData.isTenderTeamMember}
            onChange={handleChange}
            value="sidebarMini"
            classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar,
            }}
        />
        <p style={{marginBottom: 0}}>Tender-Dokument?</p>
    </div>
}

const useModalStyles3 = makeStyles({
    ...modalStyles,
    ...customSelectStyles,
    closeButton: {
        position: "absolute",
        right: 14,
        top: 14,
        cursor: "pointer",
        color: grayColor[0],
        "&:hover": {
            color: grayColor[2]
        }
    },
    btnContainer: {
        display: "flex",
        marginBottom: "4rem"
    },
    selectBtn: {
        padding: "4rem",
        border: `1px solid ${primaryColor[0]}`,
        borderRight: "none",
        cursor: "pointer",
        "&:first-child": {
            borderTopLeftRadius: 14,
            borderBottomLeftRadius: 14,
        },
        "&:last-child": {
            borderRight: `1px solid ${primaryColor[0]}`,
            borderTopRightRadius: 14,
            borderBottomRightRadius: 14,
        },
        "&:hover": {
            backgroundColor: `rgba(33, 117, 217, .05)`
        },
        "&.selected": {
            background: primaryColor[0],
            transition: "all .75s ease-in-out"
        }
    },
    selectBtnTitle: {
        color: primaryColor[0],
        fontWeight: 500,
        "&.selected": {
            color: `#fff`,
        }
    },
    selectBtnDescription: {
        textAlign: "justify",
        "&.selected": {
            color: `#fff`,
            opacity: .75
        }
    },
})

function DocumentTypePicker({isOpen, onClose}) {
    const classes = useModalStyles3();
    const [selection, setSelection] = useState(0);
    const {user} = useContext(AuthContext);
    const documentTypes = ["tender", "salesCollateral", "marketAnalytics"]

    const handleSelect = (step) => {
        setSelection(step);
        setTimeout(() => {
            onClose(documentTypes[step - 1])
        }, 2000)
    }
    return <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        transition={Transition}
        keepMounted
        onClose={() => true}
        aria-labelledby="DocumentTypePicker-modal-title"
        aria-describedby="DocumentTypePicker-modal-description"
    >
        <DialogContent
            id="DocumentTypePicker-modal-description"
            className={classes.modalBody}
        >
            <h2 style={{textAlign: "center"}}>Welche Art von Dokument willst Du erstellen?</h2>
            <div className={classes.btnContainer}>
                <div
                    className={classes.selectBtn + " " + (selection === 1 ? "selected animate__animated animate__flash animate__delay-1s" : "")}
                    onClick={() => handleSelect(1)}>
                    <h3 className={classes.selectBtnTitle + (selection === 1 ? " selected" : "")}>Angebot /
                        Ausschreibung</h3>
                    <p className={classes.selectBtnDescription + (selection === 1 ? " selected" : "")}>Diese Art von
                        Dokument erstellst Du, wenn Du mit dem Kunden bereits eine konkrete Zusammenarbeit anstrebst und
                        ihm ein passgenaues Dienstleistungsportfolio anbieten willst.</p>
                </div>

                <div
                    className={classes.selectBtn + " " + (selection === 2 ? "selected animate__animated animate__flash animate__delay-1s" : "")}
                    onClick={() => {
                        handleSelect(2)
                    }}
                >
                    <h3 className={classes.selectBtnTitle + (selection === 2 ? " selected" : "")}>Vertriebs-Unterlagen</h3>
                    <p className={classes.selectBtnDescription + (selection === 2 ? " selected" : "")}>Diese Art von
                        Dokument unterstützen dich im Vertriebsprozess und liefern dir aktuelle Informationen zu Deiner
                        OpCo und Produkt- bzw. Service-Portfolio.</p>
                </div>

                {MARKET_ANALYTICS_USERS.includes(user.email) && <div
                    className={classes.selectBtn + " " + (selection === 3 ? "selected animate__animated animate__flash animate__delay-1s" : "")}
                    onClick={() => handleSelect(3)}>
                    <h3 className={classes.selectBtnTitle + (selection === 3 ? " selected" : "")}>Marktanalyse</h3>
                    <p className={classes.selectBtnDescription + (selection === 3 ? " selected" : "")}>Hiermit werden
                        Marktanalysen von Ulf und Annegret erstellt.</p>
                </div>}
            </div>
        </DialogContent>
    </Dialog>
}

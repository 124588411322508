import React, { useEffect, useState } from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user){
        setUser(user);
      } else {
        // TODO: sign user out
      }
    });
  }, []);

  function updateUser(updatedUser){
    setUser(updatedUser)
  }

  return (
    <AuthContext.Provider value={{ user, updateUser }}>{children}</AuthContext.Provider>
  );
};

import React, {useContext, useState} from "react";
import {AuthContext} from "../../../firebase/context";
import {firebaseDB} from "../../../firebase/config";
import {getDatabase, ref, child, set, update, get} from "firebase/database";
import startIntroJs from "../intro_js";


export const DocumentBuilderContext = React.createContext();

export function DocumentBuilderProvider({ children }){
  const {user} = useContext(AuthContext);
  const userEncoded = user.email.replace(/\./g, "__dot__").replace(/@/g, "__at__");
  const [documentData, setDocumentData] = useState({
    dealTeam: [user.email, ],
    templates: [],
    filename: "", // this is only used for non-tender documents,
    isTenderTeamMember: false,
  });
  const [templateLibrary, setTemplateLibrary] = useState([]);
  const [topics, setTopics] = useState([]);
  const [tenders, setTenders] = useState([]);

  const [userProfile, setUserProfile] = useState(null);

  const loadUserProfile = () => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${userEncoded}`)).then( snapshot => {
      if (snapshot.exists()){
        let data = snapshot.val();
        let now = new Date();
        // if user profile exists
        // we'll update the user profile by timestamping the current login
        // and incrementing the number of logins
        let newProfile = {
          ...data,
          numLogins: data.numLogins ? data.numLogins + 1 : 1,
          lastLogin: now.toISOString(),
        };
        setUserProfile(newProfile);
        updateUserProfile(newProfile);
        if (!(data && data.introJs && data.introJs["welcome+"] && data.introJs["welcome+"] === "completed")){
          startIntroJs("welcome+", user)
        }
      } else {
        createUserSettings();
        startIntroJs("welcome+", user)
      }
    }).catch( err => console.log("no user settings available", err))
  }

  const createUserSettings = () => {
    const db = firebaseDB;
    let userProfileEndpoint = "users/" + userEncoded;
    let now = new Date();
    set(ref(db, userProfileEndpoint), {
      numLogins: 1,
      lastLogin: now.toISOString(),
      introJs: {
        'introJs_welcome+': "pending"
      }
    })
  }

  const updateUserProfile = (newProfile, endpoint) => {
    endpoint = endpoint ? endpoint : "";
    const db = firebaseDB;
    let userProfileEndpoint = "users/" + userEncoded;
    update(ref(db), {
      [userProfileEndpoint + endpoint]: newProfile
    })
  }

  function updateDocument(updatedDocument){
    setDocumentData(updatedDocument);
  }

  function updateTemplateLibrary(updatedTemplates){
    setTemplateLibrary(updatedTemplates)
  }

  function updateTopics(updatedTopics){
    setTopics(updatedTopics)
  }

  function updateTenders(updatedTenders){
    setTenders(updatedTenders)
  }

  return (
    <DocumentBuilderContext.Provider value={{ documentData, updateDocument, templateLibrary, updateTemplateLibrary, topics, updateTopics, tenders, updateTenders, userProfile, updateUserProfile, loadUserProfile }}>
      {children}
    </DocumentBuilderContext.Provider>
  );
};
